import {useAuth0} from "@auth0/auth0-react";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

function SignInOutNavbarButton({navbarIconButton, iconsStyle}) {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout
  } = useAuth0();
  if (!isAuthenticated) {
    return (<IconButton sx={navbarIconButton} size="small"
                        disableRipple
                        onClick={() => loginWithRedirect()}>
      <Icon sx={iconsStyle}>login</Icon>
    </IconButton>)
  }

  return (<IconButton sx={navbarIconButton} size="small"
                      disableRipple
                      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
    <Icon sx={iconsStyle}>logout</Icon>
  </IconButton>)
}

export default SignInOutNavbarButton;
