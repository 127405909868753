/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

function DefaultGeneratorCard({
  image,
  label,
  title,
  description,
  action,
  authors
}) {
  return (
      <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible",
            height: "100%",
          }}
      >
        <MDBox position="relative" width="100.25%" shadow="xl"
               borderRadius="xl">
          <CardMedia
              src={image}
              component="img"
              title={title}
              sx={{
                maxWidth: "100%",
                margin: 0,
                boxShadow: ({boxShadows: {md}}) => md,
                objectFit: "cover",
                objectPosition: "center",
              }}
          />
        </MDBox>
        <MDBox pt={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text"
                      textTransform="capitalize">
          {label}
        </MDTypography>
        </MDBox>
        <MDBox>
          {action.type === "internal" ? (
              <MDTypography
                  component={Link}
                  to={action.route}
                  variant="h5"
                  textTransform="capitalize"
              >
                {title}
              </MDTypography>
          ) : (
              <MDTypography
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  textTransform="capitalize"
              >
                {title}
              </MDTypography>
          )}
        </MDBox>
        <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
        {action.type === "internal" ? (
            <MDButton
                sx={{marginTop: "auto"}}
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
            >
              {action.label}
            </MDButton>
        ) : (
            <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
            >
              {action.label}
            </MDButton>
        )}
      </Card>
  );
}

// Typechecking props for the DefaultProjectCard
DefaultGeneratorCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultGeneratorCard;
