/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useRef, useState} from "react";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ImageUploader from "../ImageUploader";
import axios from "axios";

function ImageStyleLikeImageInputs({
  workflowClientRef,
  requestHandler,
  progressState,
  definition
}) {
  const [negativePrompt, setNegativePrompt] = useState("text, watermark");

  const [uploadedFile1, setUploadedFile1] = useState(null)
  const [uploadedFile2, setUploadedFile2] = useState(null)

  async function handleSubmit(event) {
    event.preventDefault();
    const negativePromptParam = negativePrompt
    const reference_img = uploadedFile1
    const input_img = uploadedFile2
    requestHandler(definition.type, definition.variant, {
      input_style_img: input_img,
      input_reference_img: reference_img,
      negative_prompt: negativePromptParam
    })
  }

  const cardGridContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch"
  }

  return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={cardGridContainerStyle}>
          <Grid item xs={12} md={6} xl={4}>
            <MDBox><MDTypography variant="button">Apply style to this
              image...</MDTypography></MDBox>
            <MDBox>
              <ImageUploader workflowClientRef={workflowClientRef}
                             setUploadedFile={setUploadedFile1}
                             progressState={progressState}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <MDBox><MDTypography variant="button">To make it look like this
              image...</MDTypography></MDBox>
            <MDBox>
              <ImageUploader workflowClientRef={workflowClientRef}
                             setUploadedFile={setUploadedFile2}
                             progressState={progressState}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox mt={1}>
              <MDInput
                  disabled={progressState.running}
                  label="Describe what you do not want to be included..."
                  fullWidth
                  multiline
                  rows={3}
                  value={negativePrompt}
                  onChange={(event) => setNegativePrompt(
                      event.target.value)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox>
              <MDButton
                  disabled={!uploadedFile1 || !uploadedFile2
                      || progressState.running}
                  color="dark"
                  type="submit">Generate</MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox>
              <MDTypography variant="button" fontWeight="light"
                            color={progressState.error ? "error" : "text"}>
                {progressState.message}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </form>
  );
}

export default ImageStyleLikeImageInputs;
