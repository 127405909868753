const presetValues = {
  beard: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Stubble Beard",
      display: "Stubble Beard"
    },
    {
      value: "Goatee",
      display: "Goatee"
    },
    {
      value: "Full Beard",
      display: "Full Beard"
    },
    {
      value: "Van Dyke Beard",
      display: "Van Dyke Beard"
    },
    {
      value: "Soul Patch",
      display: "Soul Patch"
    },
    {
      value: "Garibaldi Beard",
      display: "Garibaldi Beard"
    },
    {
      value: "Mutton Chops",
      display: "Mutton Chops"
    },
    {
      value: "Circle Beard",
      display: "Circle Beard"
    },
    {
      value: "Corporate Beard",
      display: "Corporate Beard"
    },
    {
      value: "Balbo Beard",
      display: "Balbo Beard"
    },
    {
      value: "Ducktail Beard",
      display: "Ducktail Beard"
    },
    {
      value: "Chinstrap Beard",
      display: "Chinstrap Beard"
    },
    {
      value: "Anchor Beard",
      display: "Anchor Beard"
    },
    {
      value: "Chevron Mustache",
      display: "Chevron Mustache"
    },
    {
      value: "Horseshoe Mustache",
      display: "Horseshoe Mustache"
    },
    {
      value: "Handlebar Mustache",
      display: "Handlebar Mustache"
    },
    {
      value: "Imperial Mustache",
      display: "Imperial Mustache"
    },
    {
      value: "Pencil Mustache",
      display: "Pencil Mustache"
    },
    {
      value: "Friendly Mutton Chops",
      display: "Friendly Mutton Chops"
    },
    {
      value: "Zappa Mustache",
      display: "Zappa Mustache"
    }
  ],
  body_type: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Beefy",
      display: "Beefy"
    },
    {
      value: "Buxom",
      display: "Buxom"
    },
    {
      value: "Buff",
      display: "Buff"
    },
    {
      value: "Chubby",
      display: "Chubby"
    },
    {
      value: "Curvy",
      display: "Curvy"
    },
    {
      value: "Fat",
      display: "Fat"
    },
    {
      value: "Fit",
      display: "Fit"
    },
    {
      value: "Flyweight",
      display: "Flyweight"
    },
    {
      value: "Hefty",
      display: "Hefty"
    },
    {
      value: "Large",
      display: "Large"
    },
    {
      value: "Lanky",
      display: "Lanky"
    },
    {
      value: "Midweight",
      display: "Midweight"
    },
    {
      value: "Morbidly obese",
      display: "Morbidly obese"
    },
    {
      value: "Muscular",
      display: "Muscular"
    },
    {
      value: "Obese",
      display: "Obese"
    },
    {
      value: "Overweight",
      display: "Overweight"
    },
    {
      value: "Petite",
      display: "Petite"
    },
    {
      value: "Plump",
      display: "Plump"
    },
    {
      value: "Portly",
      display: "Portly"
    },
    {
      value: "Rotund",
      display: "Rotund"
    },
    {
      value: "Short",
      display: "Short"
    },
    {
      value: "Skinny",
      display: "Skinny"
    },
    {
      value: "Slight",
      display: "Slight"
    },
    {
      value: "Slim",
      display: "Slim"
    },
    {
      value: "Small",
      display: "Small"
    },
    {
      value: "Stout",
      display: "Stout"
    },
    {
      value: "Stocky",
      display: "Stocky"
    },
    {
      value: "Tall",
      display: "Tall"
    },
    {
      value: "Thick",
      display: "Thick"
    },
    {
      value: "Tiny",
      display: "Tiny"
    },
    {
      value: "Voluptuous",
      display: "Voluptuous"
    },
    {
      value: "Well-built",
      display: "Well-built"
    },
    {
      value: "Well-endowed",
      display: "Well-endowed"
    },
    {
      value: "Underweight",
      display: "Underweight"
    }
  ],
  clothes: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Bohemian Dress",
      display: "Bohemian Dress"
    },
    {
      value: "Business Casual Dress",
      display: "Business Casual Dress"
    },
    {
      value: "Business Formal Dress",
      display: "Business Formal Dress"
    },
    {
      value: "Business Professional Dress",
      display: "Business Professional Dress"
    },
    {
      value: "Casual Chic Dress",
      display: "Casual Chic Dress"
    },
    {
      value: "Casual Dress",
      display: "Casual Dress"
    },
    {
      value: "Club Dress",
      display: "Club Dress"
    },
    {
      value: "Cocktail Dress",
      display: "Cocktail Dress"
    },
    {
      value: "Edgy Dress",
      display: "Edgy Dress"
    },
    {
      value: "Formal Dress",
      display: "Formal Dress"
    },
    {
      value: "Gothic Dress",
      display: "Gothic Dress"
    },
    {
      value: "Hipster Dress",
      display: "Hipster Dress"
    },
    {
      value: "Party Dress",
      display: "Party Dress"
    },
    {
      value: "Preppy Dress",
      display: "Preppy Dress"
    },
    {
      value: "Punk Dress",
      display: "Punk Dress"
    },
    {
      value: "Retro Dress",
      display: "Retro Dress"
    },
    {
      value: "Smart Casual Dress",
      display: "Smart Casual Dress"
    },
    {
      value: "Sporty Dress",
      display: "Sporty Dress"
    },
    {
      value: "Streetwear Dress",
      display: "Streetwear Dress"
    },
    {
      value: "Vintage Dress",
      display: "Vintage Dress"
    }
  ],
  eyes_color: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Albino",
      display: "Albino"
    },
    {
      value: "Amber",
      display: "Amber"
    },
    {
      value: "Blue",
      display: "Blue"
    },
    {
      value: "Brown",
      display: "Brown"
    },
    {
      value: "Green",
      display: "Green"
    },
    {
      value: "Gray",
      display: "Gray"
    },
    {
      value: "Hazel",
      display: "Hazel"
    },
    {
      value: "Heterochromia",
      display: "Heterochromia"
    },
    {
      value: "Red",
      display: "Red"
    },
    {
      value: "Violet",
      display: "Violet"
    }
  ],
  eyes_shape: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Almond Eyes Shape",
      display: "Almond Eyes Shape"
    },
    {
      value: "Asian Eyes Shape",
      display: "Asian Eyes Shape"
    },
    {
      value: "Close-Set Eyes Shape",
      display: "Close-Set Eyes Shape"
    },
    {
      value: "Deep Set Eyes Shape",
      display: "Deep Set Eyes Shape"
    },
    {
      value: "Downturned Eyes Shape",
      display: "Downturned Eyes Shape"
    },
    {
      value: "Double Eyelid Eyes Shape",
      display: "Double Eyelid Eyes Shape"
    },
    {
      value: "Hooded Eyes Shape",
      display: "Hooded Eyes Shape"
    },
    {
      value: "Monolid Eyes Shape",
      display: "Monolid Eyes Shape"
    },
    {
      value: "Oval Eyes Shape",
      display: "Oval Eyes Shape"
    },
    {
      value: "Protruding Eyes Shape",
      display: "Protruding Eyes Shape"
    },
    {
      value: "Round Eyes Shape",
      display: "Round Eyes Shape"
    },
    {
      value: "Upturned Eyes Shape",
      display: "Upturned Eyes Shape"
    }
  ],
  face_expression: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Amused",
      display: "Amused"
    },
    {
      value: "Angry",
      display: "Angry"
    },
    {
      value: "Anxious",
      display: "Anxious"
    },
    {
      value: "Bored",
      display: "Bored"
    },
    {
      value: "Calm",
      display: "Calm"
    },
    {
      value: "Cautious",
      display: "Cautious"
    },
    {
      value: "Confused",
      display: "Confused"
    },
    {
      value: "Contemptuous",
      display: "Contemptuous"
    },
    {
      value: "Content",
      display: "Content"
    },
    {
      value: "Curious",
      display: "Curious"
    },
    {
      value: "Disappointed",
      display: "Disappointed"
    },
    {
      value: "Disgusted",
      display: "Disgusted"
    },
    {
      value: "Envious",
      display: "Envious"
    },
    {
      value: "Excited",
      display: "Excited"
    },
    {
      value: "Fearful",
      display: "Fearful"
    },
    {
      value: "Happy",
      display: "Happy"
    },
    {
      value: "In love",
      display: "In love"
    },
    {
      value: "Nervous",
      display: "Nervous"
    },
    {
      value: "Peaceful",
      display: "Peaceful"
    },
    {
      value: "Pensive",
      display: "Pensive"
    },
    {
      value: "Prideful",
      display: "Prideful"
    },
    {
      value: "Proud",
      display: "Proud"
    },
    {
      value: "Relieved",
      display: "Relieved"
    },
    {
      value: "Sad",
      display: "Sad"
    },
    {
      value: "Sarcastic",
      display: "Sarcastic"
    },
    {
      value: "Serene",
      display: "Serene"
    },
    {
      value: "Serious",
      display: "Serious"
    },
    {
      value: "Shy",
      display: "Shy"
    },
    {
      value: "Silly",
      display: "Silly"
    },
    {
      value: "Smiling",
      display: "Smiling"
    },
    {
      value: "Surprised",
      display: "Surprised"
    },
    {
      value: "Surprised and Amused",
      display: "Surprised and Amused"
    }
  ],
  face_shape: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Circle",
      display: "Circle"
    },
    {
      value: "Diamond",
      display: "Diamond"
    },
    {
      value: "Heart",
      display: "Heart"
    },
    {
      value: "Heart with Pointed Chin",
      display: "Heart with Pointed Chin"
    },
    {
      value: "Heart with Rounded Chin",
      display: "Heart with Rounded Chin"
    },
    {
      value: "Heart with V-Shape Chin",
      display: "Heart with V-Shape Chin"
    },
    {
      value: "Inverted Triangle",
      display: "Inverted Triangle"
    },
    {
      value: "Long",
      display: "Long"
    },
    {
      value: "Oblong",
      display: "Oblong"
    },
    {
      value: "Oval",
      display: "Oval"
    },
    {
      value: "Pear",
      display: "Pear"
    },
    {
      value: "Rectangle",
      display: "Rectangle"
    },
    {
      value: "Round",
      display: "Round"
    },
    {
      value: "Round with Defined Cheekbones",
      display: "Round with Defined Cheekbones"
    },
    {
      value: "Round with High Cheekbones",
      display: "Round with High Cheekbones"
    },
    {
      value: "Round with Soft Cheekbones",
      display: "Round with Soft Cheekbones"
    },
    {
      value: "Square",
      display: "Square"
    },
    {
      value: "Square Oval",
      display: "Square Oval"
    },
    {
      value: "Square Round",
      display: "Square Round"
    },
    {
      value: "Square with Rounded Jaw",
      display: "Square with Rounded Jaw"
    },
    {
      value: "Square with Sharp Jaw",
      display: "Square with Sharp Jaw"
    },
    {
      value: "Square with Soft Jaw",
      display: "Square with Soft Jaw"
    },
    {
      value: "Triangle",
      display: "Triangle"
    }
  ],
  gender: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Man",
      display: "Man"
    },
    {
      value: "Woman",
      display: "Woman"
    }
  ],
  hair_color: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Auburn",
      display: "Auburn"
    },
    {
      value: "Black",
      display: "Black"
    },
    {
      value: "Blonde",
      display: "Blonde"
    },
    {
      value: "Burgundy",
      display: "Burgundy"
    },
    {
      value: "Caramel",
      display: "Caramel"
    },
    {
      value: "Chestnut",
      display: "Chestnut"
    },
    {
      value: "Chocolate",
      display: "Chocolate"
    },
    {
      value: "Copper",
      display: "Copper"
    },
    {
      value: "Dirty",
      display: "Dirty"
    },
    {
      value: "Gray",
      display: "Gray"
    },
    {
      value: "Honey",
      display: "Honey"
    },
    {
      value: "Jet Black",
      display: "Jet Black"
    },
    {
      value: "Mahogany",
      display: "Mahogany"
    },
    {
      value: "Multicolored",
      display: "Multicolored"
    },
    {
      value: "Pastel",
      display: "Pastel"
    },
    {
      value: "Platinum",
      display: "Platinum"
    },
    {
      value: "Red",
      display: "Red"
    },
    {
      value: "Salt and pepper",
      display: "Salt and pepper"
    },
    {
      value: "Silver",
      display: "Silver"
    },
    {
      value: "Strawberry",
      display: "Strawberry"
    },
    {
      value: "White",
      display: "White"
    }
  ],
  hair_length: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Short hair",
      display: "Short hair"
    },
    {
      value: "Medium hair",
      display: "Medium hair"
    },
    {
      value: "Long hair",
      display: "Long hair"
    }
  ],
  hair_style: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Afro",
      display: "Afro"
    },
    {
      value: "A-line bob",
      display: "A-line bob"
    },
    {
      value: "Asymmetrical",
      display: "Asymmetrical"
    },
    {
      value: "Balayage",
      display: "Balayage"
    },
    {
      value: "Bald",
      display: "Bald"
    },
    {
      value: "Ballerina bun",
      display: "Ballerina bun"
    },
    {
      value: "Bangs",
      display: "Bangs"
    },
    {
      value: "Beehive",
      display: "Beehive"
    },
    {
      value: "Beehivecut",
      display: "Beehivecut"
    },
    {
      value: "Bleached spikes",
      display: "Bleached spikes"
    },
    {
      value: "Blunt bob",
      display: "Blunt bob"
    },
    {
      value: "Blunt",
      display: "Blunt"
    },
    {
      value: "Bob",
      display: "Bob"
    },
    {
      value: "Bouffant",
      display: "Bouffant"
    },
    {
      value: "Bowl",
      display: "Bowl"
    },
    {
      value: "Box braids",
      display: "Box braids"
    },
    {
      value: "Box fade",
      display: "Box fade"
    },
    {
      value: "Braided",
      display: "Braided"
    },
    {
      value: "Braided bob",
      display: "Braided bob"
    },
    {
      value: "Braided pigtails",
      display: "Braided pigtails"
    },
    {
      value: "Brave shortcut with shaved sides",
      display: "Brave shortcut with shaved sides"
    },
    {
      value: "Bushy",
      display: "Bushy"
    },
    {
      value: "Buzz",
      display: "Buzz"
    },
    {
      value: "Caesar",
      display: "Caesar"
    },
    {
      value: "Chignon",
      display: "Chignon"
    },
    {
      value: "Choppy",
      display: "Choppy"
    },
    {
      value: "Cloudy",
      display: "Cloudy"
    },
    {
      value: "Cornrows",
      display: "Cornrows"
    },
    {
      value: "Cornrows",
      display: "Cornrows"
    },
    {
      value: "Crew",
      display: "Crew"
    },
    {
      value: "Curly",
      display: "Curly"
    },
    {
      value: "Curly bob",
      display: "Curly bob"
    },
    {
      value: "Curly Frizzy",
      display: "Curly Frizzy"
    },
    {
      value: "Curtain bangs",
      display: "Curtain bangs"
    },
    {
      value: "Deep side part",
      display: "Deep side part"
    },
    {
      value: "Double Bun",
      display: "Double Bun"
    },
    {
      value: "Dreadlocks",
      display: "Dreadlocks"
    },
    {
      value: "Faded afro",
      display: "Faded afro"
    },
    {
      value: "Faux hawk",
      display: "Faux hawk"
    },
    {
      value: "Faux hawk short pixie",
      display: "Faux hawk short pixie"
    },
    {
      value: "Feathered",
      display: "Feathered"
    },
    {
      value: "Female bald",
      display: "Female bald"
    },
    {
      value: "Fishtail braids",
      display: "Fishtail braids"
    },
    {
      value: "Flat topcut",
      display: "Flat topcut"
    },
    {
      value: "French bob",
      display: "French bob"
    },
    {
      value: "French braids",
      display: "French braids"
    },
    {
      value: "French twist",
      display: "French twist"
    },
    {
      value: "Frohawk",
      display: "Frohawk"
    },
    {
      value: "Hair ringlets",
      display: "Hair ringlets"
    },
    {
      value: "High ponytail",
      display: "High ponytail"
    },
    {
      value: "High skin fade",
      display: "High skin fade"
    },
    {
      value: "Honey",
      display: "Honey"
    },
    {
      value: "Italian bob",
      display: "Italian bob"
    },
    {
      value: "Layered",
      display: "Layered"
    },
    {
      value: "Lemonade braids",
      display: "Lemonade braids"
    },
    {
      value: "Long bob",
      display: "Long bob"
    },
    {
      value: "Long with bangs",
      display: "Long with bangs"
    },
    {
      value: "Long pixie",
      display: "Long pixie"
    },
    {
      value: "Long ponytail",
      display: "Long ponytail"
    },
    {
      value: "Long straight",
      display: "Long straight"
    },
    {
      value: "Loose Curly Afro",
      display: "Loose Curly Afro"
    },
    {
      value: "Marmaid waves",
      display: "Marmaid waves"
    },
    {
      value: "Micro braids",
      display: "Micro braids"
    },
    {
      value: "Middle part ponytails",
      display: "Middle part ponytails"
    },
    {
      value: "Modern caesar",
      display: "Modern caesar"
    },
    {
      value: "Mohawk",
      display: "Mohawk"
    },
    {
      value: "Multicolored",
      display: "Multicolored"
    },
    {
      value: "Pastel",
      display: "Pastel"
    },
    {
      value: "Pigtails",
      display: "Pigtails"
    },
    {
      value: "Pixie",
      display: "Pixie"
    },
    {
      value: "Platinum",
      display: "Platinum"
    },
    {
      value: "Pompadour",
      display: "Pompadour"
    },
    {
      value: "Quiff",
      display: "Quiff"
    },
    {
      value: "Razor fade with curls",
      display: "Razor fade with curls"
    },
    {
      value: "Red",
      display: "Red"
    },
    {
      value: "Right side shaved",
      display: "Right side shaved"
    },
    {
      value: "Salt and pepper",
      display: "Salt and pepper"
    },
    {
      value: "Shag",
      display: "Shag"
    },
    {
      value: "Short curly",
      display: "Short curly"
    },
    {
      value: "Short curly pixie",
      display: "Short curly pixie"
    },
    {
      value: "Short",
      display: "Short"
    },
    {
      value: "Short messy curls",
      display: "Short messy curls"
    },
    {
      value: "Shoulder Length with Bangs",
      display: "Shoulder Length with Bangs"
    },
    {
      value: "Shoulder length straight",
      display: "Shoulder length straight"
    },
    {
      value: "Side braid",
      display: "Side braid"
    },
    {
      value: "Side Part Comb-Overstyle With High Fade",
      display: "Side Part Comb-Overstyle With High Fade"
    },
    {
      value: "Side-swept bangs",
      display: "Side-swept bangs"
    },
    {
      value: "Side-swept fringe",
      display: "Side-swept fringe"
    },
    {
      value: "Sideswept pixie",
      display: "Sideswept pixie"
    },
    {
      value: "Smooth lob",
      display: "Smooth lob"
    },
    {
      value: "Space buns",
      display: "Space buns"
    },
    {
      value: "Spiky",
      display: "Spiky"
    },
    {
      value: "Spiky",
      display: "Spiky"
    },
    {
      value: "Stacked bob",
      display: "Stacked bob"
    },
    {
      value: "Stacked Curls in Short Bob",
      display: "Stacked Curls in Short Bob"
    },
    {
      value: "Stitch braids",
      display: "Stitch braids"
    },
    {
      value: "Strawberry",
      display: "Strawberry"
    },
    {
      value: "Strawberry blonde",
      display: "Strawberry blonde"
    },
    {
      value: "Sweeping pixie",
      display: "Sweeping pixie"
    },
    {
      value: "Taper fade with waves",
      display: "Taper fade with waves"
    },
    {
      value: "Taperedcut with shaved side",
      display: "Taperedcut with shaved side"
    },
    {
      value: "Textured brush back",
      display: "Textured brush back"
    },
    {
      value: "Textured",
      display: "Textured"
    },
    {
      value: "Tomboy",
      display: "Tomboy"
    },
    {
      value: "Top Knot",
      display: "Top Knot"
    },
    {
      value: "Twin braids",
      display: "Twin braids"
    },
    {
      value: "Twintails",
      display: "Twintails"
    },
    {
      value: "Two dutch braids",
      display: "Two dutch braids"
    },
    {
      value: "Undercut",
      display: "Undercut"
    },
    {
      value: "Updo",
      display: "Updo"
    },
    {
      value: "Very long wave",
      display: "Very long wave"
    },
    {
      value: "Waterfall braids",
      display: "Waterfall braids"
    },
    {
      value: "Wavy",
      display: "Wavy"
    },
    {
      value: "Wavy bob",
      display: "Wavy bob"
    },
    {
      value: "Wavy with curtain bangs",
      display: "Wavy with curtain bangs"
    },
    {
      value: "Wavy French Bob Vibes from 1920",
      display: "Wavy French Bob Vibes from 1920"
    },
    {
      value: "Wavy undercut",
      display: "Wavy undercut"
    }
  ],
  light_direction: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "from top",
      display: "from top"
    },
    {
      value: "from bottom",
      display: "from bottom"
    },
    {
      value: "from right",
      display: "from right"
    },
    {
      value: "from left",
      display: "from left"
    },
    {
      value: "from front",
      display: "from front"
    },
    {
      value: "from rear",
      display: "from rear"
    },
    {
      value: "from top-right",
      display: "from top-right"
    },
    {
      value: "from top-left",
      display: "from top-left"
    },
    {
      value: "from bottom-right",
      display: "from bottom-right"
    },
    {
      value: "from bottom-left",
      display: "from bottom-left"
    }
  ],
  light_type: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Abstract Light Patterns",
      display: "Abstract Light Patterns"
    },
    {
      value: "Ambient Light",
      display: "Ambient Light"
    },
    {
      value: "Backlit Silhouette Light",
      display: "Backlit Silhouette Light"
    },
    {
      value: "Backlight Light",
      display: "Backlight Light"
    },
    {
      value: "Blue Hour Light",
      display: "Blue Hour Light"
    },
    {
      value: "Bloom Light",
      display: "Bloom Light"
    },
    {
      value: "Broad Lighting Light",
      display: "Broad Lighting Light"
    },
    {
      value: "Bulb Light",
      display: "Bulb Light"
    },
    {
      value: "Buterfly Lighting Light",
      display: "Buterfly Lighting Light"
    },
    {
      value: "Candlelight",
      display: "Candlelight"
    },
    {
      value: "Caustics Light",
      display: "Caustics Light"
    },
    {
      value: "Cinematic Lighting Light",
      display: "Cinematic Lighting Light"
    },
    {
      value: "City Streetlights Light",
      display: "City Streetlights Light"
    },
    {
      value: "Cloudy Day Diffused Light",
      display: "Cloudy Day Diffused Light"
    },
    {
      value: "Corona Light",
      display: "Corona Light"
    },
    {
      value: "Crepuscular Rays Light",
      display: "Crepuscular Rays Light"
    },
    {
      value: "Dappled Sunlight Light",
      display: "Dappled Sunlight Light"
    },
    {
      value: "Dimly Lit Light",
      display: "Dimly Lit Light"
    },
    {
      value: "Fill Light",
      display: "Fill Light"
    },
    {
      value: "Firelight",
      display: "Firelight"
    },
    {
      value: "Flash Photography Light",
      display: "Flash Photography Light"
    },
    {
      value: "Fluorescent Lighting",
      display: "Fluorescent Lighting"
    },
    {
      value: "Fog and Mist Light",
      display: "Fog and Mist Light"
    },
    {
      value: "Glare Light",
      display: "Glare Light"
    },
    {
      value: "Golden Hour Light",
      display: "Golden Hour Light"
    },
    {
      value: "Halos Light",
      display: "Halos Light"
    },
    {
      value: "Harsh Sunlight Light",
      display: "Harsh Sunlight Light"
    },
    {
      value: "Heat Haze Light",
      display: "Heat Haze Light"
    },
    {
      value: "High-Key Lighting Light",
      display: "High-Key Lighting Light"
    },
    {
      value: "Incandescent Lighting",
      display: "Incandescent Lighting"
    },
    {
      value: "Lantern Light",
      display: "Lantern Light"
    },
    {
      value: "Lens Flare Light",
      display: "Lens Flare Light"
    },
    {
      value: "Lightning Light",
      display: "Lightning Light"
    },
    {
      value: "Low-Key Lighting Light",
      display: "Low-Key Lighting Light"
    },
    {
      value: "Loop Lighting Light",
      display: "Loop Lighting Light"
    },
    {
      value: "Mist Light",
      display: "Mist Light"
    },
    {
      value: "Moonlight Light",
      display: "Moonlight Light"
    },
    {
      value: "Natural Light",
      display: "Natural Light"
    },
    {
      value: "Neon Lights Light",
      display: "Neon Lights Light"
    },
    {
      value: "Overcast Sky Light",
      display: "Overcast Sky Light"
    },
    {
      value: "Rainbows Light",
      display: "Rainbows Light"
    },
    {
      value: "Reflections Light",
      display: "Reflections Light"
    },
    {
      value: "Rembrandt Lighting Light",
      display: "Rembrandt Lighting Light"
    },
    {
      value: "Rim Light",
      display: "Rim Light"
    },
    {
      value: "Rim Lighting Light",
      display: "Rim Lighting Light"
    },
    {
      value: "Scattering Light",
      display: "Scattering Light"
    },
    {
      value: "Shadows Light",
      display: "Shadows Light"
    },
    {
      value: "Side Light",
      display: "Side Light"
    },
    {
      value: "Silhouette Light",
      display: "Silhouette Light"
    },
    {
      value: "Skylight Light",
      display: "Skylight Light"
    },
    {
      value: "Soft Ambient Light",
      display: "Soft Ambient Light"
    },
    {
      value: "Soft Light",
      display: "Soft Light"
    },
    {
      value: "Softbox Lighting Light",
      display: "Softbox Lighting Light"
    },
    {
      value: "Speedlight Light",
      display: "Speedlight Light"
    },
    {
      value: "Split Lighting Light",
      display: "Split Lighting Light"
    },
    {
      value: "Spot Light",
      display: "Spot Light"
    },
    {
      value: "Spotlight Light",
      display: "Spotlight Light"
    },
    {
      value: "Stage Lighting Light",
      display: "Stage Lighting Light"
    },
    {
      value: "Strip Light",
      display: "Strip Light"
    },
    {
      value: "Studio Lighting Light",
      display: "Studio Lighting Light"
    },
    {
      value: "Sunlight Light",
      display: "Sunlight Light"
    },
    {
      value: "Sunset Glow Light",
      display: "Sunset Glow Light"
    },
    {
      value: "Sunrise Warmth Light",
      display: "Sunrise Warmth Light"
    },
    {
      value: "Thuderstorms Light",
      display: "Thuderstorms Light"
    },
    {
      value: "Torchlight Light",
      display: "Torchlight Light"
    },
    {
      value: "Top Light",
      display: "Top Light"
    },
    {
      value: "Tungsten Lighting Light",
      display: "Tungsten Lighting Light"
    },
    {
      value: "Twilight Hues Light",
      display: "Twilight Hues Light"
    },
    {
      value: "Underlighting Light",
      display: "Underlighting Light"
    },
    {
      value: "Volumetric Lighting Light",
      display: "Volumetric Lighting Light"
    }
  ],
  lips_color: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Berry Lips",
      display: "Berry Lips"
    },
    {
      value: "Black Lips",
      display: "Black Lips"
    },
    {
      value: "Blue Lips",
      display: "Blue Lips"
    },
    {
      value: "Brown Lips",
      display: "Brown Lips"
    },
    {
      value: "Burgundy Lips",
      display: "Burgundy Lips"
    },
    {
      value: "Coral Lips",
      display: "Coral Lips"
    },
    {
      value: "Glossy Red Lips",
      display: "Glossy Red Lips"
    },
    {
      value: "Mauve Lips",
      display: "Mauve Lips"
    },
    {
      value: "Orange Lips",
      display: "Orange Lips"
    },
    {
      value: "Peach Lips",
      display: "Peach Lips"
    },
    {
      value: "Pink Lips",
      display: "Pink Lips"
    },
    {
      value: "Plum Lips",
      display: "Plum Lips"
    },
    {
      value: "Purple Lips",
      display: "Purple Lips"
    },
    {
      value: "Red Lips",
      display: "Red Lips"
    },
    {
      value: "Yellow Lips",
      display: "Yellow Lips"
    }
  ],
  lips_shape: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Biting Lips",
      display: "Biting Lips"
    },
    {
      value: "Bow-shaped Lips",
      display: "Bow-shaped Lips"
    },
    {
      value: "Closed Lips",
      display: "Closed Lips"
    },
    {
      value: "Cupid's Bow Lips",
      display: "Cupid's Bow Lips"
    },
    {
      value: "Defined Cupid's Bow Lips",
      display: "Defined Cupid's Bow Lips"
    },
    {
      value: "Flat Cupid's Bow Lips",
      display: "Flat Cupid's Bow Lips"
    },
    {
      value: "Full Lips",
      display: "Full Lips"
    },
    {
      value: "Heart-shaped Lips",
      display: "Heart-shaped Lips"
    },
    {
      value: "Large Lips",
      display: "Large Lips"
    },
    {
      value: "Medium Lips",
      display: "Medium Lips"
    },
    {
      value: "Neutral Lips",
      display: "Neutral Lips"
    },
    {
      value: "Parted Lips",
      display: "Parted Lips"
    },
    {
      value: "Plump Lips",
      display: "Plump Lips"
    },
    {
      value: "Pouting Lips",
      display: "Pouting Lips"
    },
    {
      value: "Round Lips",
      display: "Round Lips"
    },
    {
      value: "Small Lips",
      display: "Small Lips"
    },
    {
      value: "Smiling Lips",
      display: "Smiling Lips"
    },
    {
      value: "Soft Cupid's Bow Lips",
      display: "Soft Cupid's Bow Lips"
    },
    {
      value: "Thin Lips",
      display: "Thin Lips"
    },
    {
      value: "Upper Lip Mole Lips",
      display: "Upper Lip Mole Lips"
    },
    {
      value: "Wide Lips",
      display: "Wide Lips"
    }
  ],
  makeup: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Anime Makeup",
      display: "Anime Makeup"
    },
    {
      value: "Artistic Makeup",
      display: "Artistic Makeup"
    },
    {
      value: "Avant-garde Makeup",
      display: "Avant-garde Makeup"
    },
    {
      value: "Bohemian Makeup",
      display: "Bohemian Makeup"
    },
    {
      value: "Boho Makeup",
      display: "Boho Makeup"
    },
    {
      value: "Classic Makeup",
      display: "Classic Makeup"
    },
    {
      value: "Cut Crease Makeup",
      display: "Cut Crease Makeup"
    },
    {
      value: "Dewy Makeup",
      display: "Dewy Makeup"
    },
    {
      value: "Edgy Makeup",
      display: "Edgy Makeup"
    },
    {
      value: "Festival Makeup",
      display: "Festival Makeup"
    },
    {
      value: "Glam Makeup",
      display: "Glam Makeup"
    },
    {
      value: "Glowy Makeup",
      display: "Glowy Makeup"
    },
    {
      value: "Golden Makeup",
      display: "Golden Makeup"
    },
    {
      value: "Monochromatic Makeup",
      display: "Monochromatic Makeup"
    },
    {
      value: "Natural Makeup",
      display: "Natural Makeup"
    },
    {
      value: "No-Makeup",
      display: "No-Makeup"
    },
    {
      value: "Party Makeup",
      display: "Party Makeup"
    },
    {
      value: "Retro Makeup",
      display: "Retro Makeup"
    },
    {
      value: "Sunset Eye Makeup",
      display: "Sunset Eye Makeup"
    },
    {
      value: "Vintage Makeup",
      display: "Vintage Makeup"
    },
    {
      value: "Watercolor Makeup",
      display: "Watercolor Makeup"
    }
  ],
  model_pose: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Adjusting Clothing Pose",
      display: "Adjusting Clothing Pose"
    },
    {
      value: "Against a Wall Pose",
      display: "Against a Wall Pose"
    },
    {
      value: "Ajusting Hair Pose",
      display: "Ajusting Hair Pose"
    },
    {
      value: "Arms Up Pose",
      display: "Arms Up Pose"
    },
    {
      value: "Artistic Dance Pose",
      display: "Artistic Dance Pose"
    },
    {
      value: "Back Arch Pose",
      display: "Back Arch Pose"
    },
    {
      value: "Battle Pose",
      display: "Battle Pose"
    },
    {
      value: "Bending Pose",
      display: "Bending Pose"
    },
    {
      value: "Captivating Pose",
      display: "Captivating Pose"
    },
    {
      value: "Cartwheel Pose",
      display: "Cartwheel Pose"
    },
    {
      value: "Casual Stroll Pose",
      display: "Casual Stroll Pose"
    },
    {
      value: "Chassing Pose",
      display: "Chassing Pose"
    },
    {
      value: "Classical Dance Pose",
      display: "Classical Dance Pose"
    },
    {
      value: "Close-Up Beauty Shot Pose",
      display: "Close-Up Beauty Shot Pose"
    },
    {
      value: "Confident Stance Pose",
      display: "Confident Stance Pose"
    },
    {
      value: "Contrapposto Pose",
      display: "Contrapposto Pose"
    },
    {
      value: "Crouching Pose",
      display: "Crouching Pose"
    },
    {
      value: "Dancing Pose",
      display: "Dancing Pose"
    },
    {
      value: "Dynamic Action Pose",
      display: "Dynamic Action Pose"
    },
    {
      value: "Dynamic Jump Pose",
      display: "Dynamic Jump Pose"
    },
    {
      value: "Dynamic Sitting Pose",
      display: "Dynamic Sitting Pose"
    },
    {
      value: "Eating Pose",
      display: "Eating Pose"
    },
    {
      value: "Expressive Female Pose",
      display: "Expressive Female Pose"
    },
    {
      value: "Fashion Model Pose",
      display: "Fashion Model Pose"
    },
    {
      value: "Feminine Pose",
      display: "Feminine Pose"
    },
    {
      value: "Flipping Hair Pose",
      display: "Flipping Hair Pose"
    },
    {
      value: "Gazing into the Distance Pose",
      display: "Gazing into the Distance Pose"
    },
    {
      value: "Glamour Pose",
      display: "Glamour Pose"
    },
    {
      value: "Hand-on-Hip Pose",
      display: "Hand-on-Hip Pose"
    },
    {
      value: "Handstand Pose",
      display: "Handstand Pose"
    },
    {
      value: "Headshot Pose",
      display: "Headshot Pose"
    },
    {
      value: "High Fashion Pose",
      display: "High Fashion Pose"
    },
    {
      value: "Holding Bag Pose",
      display: "Holding Bag Pose"
    },
    {
      value: "Holding Glass Pose",
      display: "Holding Glass Pose"
    },
    {
      value: "Holding Headset Pose",
      display: "Holding Headset Pose"
    },
    {
      value: "Holding Ice Cream Pose",
      display: "Holding Ice Cream Pose"
    },
    {
      value: "Holding Microphone Pose",
      display: "Holding Microphone Pose"
    },
    {
      value: "Holding Phone Pose",
      display: "Holding Phone Pose"
    },
    {
      value: "Holding Pen Pose",
      display: "Holding Pen Pose"
    },
    {
      value: "Interacting with Props Pose",
      display: "Interacting with Props Pose"
    },
    {
      value: "Jojo Pose",
      display: "Jojo Pose"
    },
    {
      value: "Jumping Pose",
      display: "Jumping Pose"
    },
    {
      value: "Kneeling Pose",
      display: "Kneeling Pose"
    },
    {
      value: "Kung Fu Pose",
      display: "Kung Fu Pose"
    },
    {
      value: "Laughing Candidly Pose",
      display: "Laughing Candidly Pose"
    },
    {
      value: "Leaning Pose",
      display: "Leaning Pose"
    },
    {
      value: "Lifting Pose",
      display: "Lifting Pose"
    },
    {
      value: "Looking Back Over Shoulder Pose",
      display: "Looking Back Over Shoulder Pose"
    },
    {
      value: "Lying Down Pose",
      display: "Lying Down Pose"
    },
    {
      value: "Magic Pose",
      display: "Magic Pose"
    },
    {
      value: "Masculine Pose",
      display: "Masculine Pose"
    },
    {
      value: "Meditation Pose",
      display: "Meditation Pose"
    },
    {
      value: "Movement Pose",
      display: "Movement Pose"
    },
    {
      value: "Muscle Pose",
      display: "Muscle Pose"
    },
    {
      value: "One Hand on Face Pose",
      display: "One Hand on Face Pose"
    },
    {
      value: "One Leg Up Pose",
      display: "One Leg Up Pose"
    },
    {
      value: "Over-the-Shoulder Look Pose",
      display: "Over-the-Shoulder Look Pose"
    },
    {
      value: "Power Pose",
      display: "Power Pose"
    },
    {
      value: "Reaching Pose",
      display: "Reaching Pose"
    },
    {
      value: "Reading Book Pose",
      display: "Reading Book Pose"
    },
    {
      value: "Relaxing Pose",
      display: "Relaxing Pose"
    },
    {
      value: "Relaxing Sitting Pose",
      display: "Relaxing Sitting Pose"
    },
    {
      value: "Reaching Pose",
      display: "Reaching Pose"
    },
    {
      value: "Riding Pose",
      display: "Riding Pose"
    },
    {
      value: "Royal Pose",
      display: "Royal Pose"
    },
    {
      value: "Running Pose",
      display: "Running Pose"
    },
    {
      value: "S-curve Pose",
      display: "S-curve Pose"
    },
    {
      value: "S-shape Pose",
      display: "S-shape Pose"
    },
    {
      value: "Sexy Pose",
      display: "Sexy Pose"
    },
    {
      value: "Sitting Cross-Legged Pose",
      display: "Sitting Cross-Legged Pose"
    },
    {
      value: "Sitting Pose",
      display: "Sitting Pose"
    },
    {
      value: "Skipping Pose",
      display: "Skipping Pose"
    },
    {
      value: "Smoking Pose",
      display: "Smoking Pose"
    },
    {
      value: "Standing Pose",
      display: "Standing Pose"
    },
    {
      value: "Stretching Pose",
      display: "Stretching Pose"
    },
    {
      value: "The Over-the-Shoulder Look Pose",
      display: "The Over-the-Shoulder Look Pose"
    },
    {
      value: "Tilted Head Pose",
      display: "Tilted Head Pose"
    },
    {
      value: "Touching Ear Pose",
      display: "Touching Ear Pose"
    },
    {
      value: "Touching Face Pose",
      display: "Touching Face Pose"
    },
    {
      value: "Touching Hair Pose",
      display: "Touching Hair Pose"
    },
    {
      value: "Twirling Pose",
      display: "Twirling Pose"
    },
    {
      value: "Upturned Eyes Shape",
      display: "Upturned Eyes Shape"
    },
    {
      value: "Vogue Pose",
      display: "Vogue Pose"
    },
    {
      value: "Walking Pose",
      display: "Walking Pose"
    },
    {
      value: "Watercolor Makeup Pose",
      display: "Watercolor Makeup Pose"
    },
    {
      value: "Waving Hand Pose",
      display: "Waving Hand Pose"
    },
    {
      value: "Yoga Pose",
      display: "Yoga Pose"
    }
  ],
  nationality: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Afghan",
      display: "Afghan"
    },
    {
      value: "Albanian",
      display: "Albanian"
    },
    {
      value: "Algerian",
      display: "Algerian"
    },
    {
      value: "Andorran",
      display: "Andorran"
    },
    {
      value: "Angolan",
      display: "Angolan"
    },
    {
      value: "Antiguans Barbudans",
      display: "Antiguans Barbudans"
    },
    {
      value: "Argentine",
      display: "Argentine"
    },
    {
      value: "Armenian",
      display: "Armenian"
    },
    {
      value: "Australian",
      display: "Australian"
    },
    {
      value: "Austrian",
      display: "Austrian"
    },
    {
      value: "Azerbaijani",
      display: "Azerbaijani"
    },
    {
      value: "Bahamian",
      display: "Bahamian"
    },
    {
      value: "Bahraini",
      display: "Bahraini"
    },
    {
      value: "Bangladeshi",
      display: "Bangladeshi"
    },
    {
      value: "Barbadian",
      display: "Barbadian"
    },
    {
      value: "Belarusian",
      display: "Belarusian"
    },
    {
      value: "Belgian",
      display: "Belgian"
    },
    {
      value: "Belizean",
      display: "Belizean"
    },
    {
      value: "Beninese",
      display: "Beninese"
    },
    {
      value: "Bhutanese",
      display: "Bhutanese"
    },
    {
      value: "Bolivian",
      display: "Bolivian"
    },
    {
      value: "Bosnian Herzegovinian",
      display: "Bosnian Herzegovinian"
    },
    {
      value: "Brazilian",
      display: "Brazilian"
    },
    {
      value: "British",
      display: "British"
    },
    {
      value: "Bruneian",
      display: "Bruneian"
    },
    {
      value: "Bulgarian",
      display: "Bulgarian"
    },
    {
      value: "Burkinabe",
      display: "Burkinabe"
    },
    {
      value: "Burundian",
      display: "Burundian"
    },
    {
      value: "Cambodian",
      display: "Cambodian"
    },
    {
      value: "Cameroonian",
      display: "Cameroonian"
    },
    {
      value: "Canadian",
      display: "Canadian"
    },
    {
      value: "Cape Verdian",
      display: "Cape Verdian"
    },
    {
      value: "Central African",
      display: "Central African"
    },
    {
      value: "Chadian",
      display: "Chadian"
    },
    {
      value: "Chilean",
      display: "Chilean"
    },
    {
      value: "Chinese",
      display: "Chinese"
    },
    {
      value: "Colombian",
      display: "Colombian"
    },
    {
      value: "Comoran",
      display: "Comoran"
    },
    {
      value: "Congolese",
      display: "Congolese"
    },
    {
      value: "Costa Rican",
      display: "Costa Rican"
    },
    {
      value: "Croatian",
      display: "Croatian"
    },
    {
      value: "Cuban",
      display: "Cuban"
    },
    {
      value: "Cypriot",
      display: "Cypriot"
    },
    {
      value: "Czech",
      display: "Czech"
    },
    {
      value: "Danish",
      display: "Danish"
    },
    {
      value: "Djibouti",
      display: "Djibouti"
    },
    {
      value: "Dominican",
      display: "Dominican"
    },
    {
      value: "Dutch",
      display: "Dutch"
    },
    {
      value: "East Timorese",
      display: "East Timorese"
    },
    {
      value: "Ecuadorean",
      display: "Ecuadorean"
    },
    {
      value: "Egyptian",
      display: "Egyptian"
    },
    {
      value: "Emirian",
      display: "Emirian"
    },
    {
      value: "Equatorial Guinean",
      display: "Equatorial Guinean"
    },
    {
      value: "Eritrean",
      display: "Eritrean"
    },
    {
      value: "Estonian",
      display: "Estonian"
    },
    {
      value: "Ethiopian",
      display: "Ethiopian"
    },
    {
      value: "Fijian",
      display: "Fijian"
    },
    {
      value: "Filipino",
      display: "Filipino"
    },
    {
      value: "Finnish",
      display: "Finnish"
    },
    {
      value: "French",
      display: "French"
    },
    {
      value: "Gabonese",
      display: "Gabonese"
    },
    {
      value: "Gambian",
      display: "Gambian"
    },
    {
      value: "Georgian",
      display: "Georgian"
    },
    {
      value: "German",
      display: "German"
    },
    {
      value: "Ghanaian",
      display: "Ghanaian"
    },
    {
      value: "Greek",
      display: "Greek"
    },
    {
      value: "Grenadian",
      display: "Grenadian"
    },
    {
      value: "Guatemalan",
      display: "Guatemalan"
    },
    {
      value: "Guinean",
      display: "Guinean"
    },
    {
      value: "Guyanese",
      display: "Guyanese"
    },
    {
      value: "Haitian",
      display: "Haitian"
    },
    {
      value: "Herzegovinian",
      display: "Herzegovinian"
    },
    {
      value: "Honduran",
      display: "Honduran"
    },
    {
      value: "Hungarian",
      display: "Hungarian"
    },
    {
      value: "Icelander",
      display: "Icelander"
    },
    {
      value: "Indian",
      display: "Indian"
    },
    {
      value: "Indonesian",
      display: "Indonesian"
    },
    {
      value: "Iranian",
      display: "Iranian"
    },
    {
      value: "Iraqi",
      display: "Iraqi"
    },
    {
      value: "Irish",
      display: "Irish"
    },
    {
      value: "Israeli",
      display: "Israeli"
    },
    {
      value: "Italian",
      display: "Italian"
    },
    {
      value: "Ivorian",
      display: "Ivorian"
    },
    {
      value: "Jamaican",
      display: "Jamaican"
    },
    {
      value: "Japanese",
      display: "Japanese"
    },
    {
      value: "Jordanian",
      display: "Jordanian"
    },
    {
      value: "Kazakhstani",
      display: "Kazakhstani"
    },
    {
      value: "Kenyan",
      display: "Kenyan"
    },
    {
      value: "Kiribati",
      display: "Kiribati"
    },
    {
      value: "North Korean",
      display: "North Korean"
    },
    {
      value: "South Korean",
      display: "South Korean"
    },
    {
      value: "Kuwaiti",
      display: "Kuwaiti"
    },
    {
      value: "Kyrgyz",
      display: "Kyrgyz"
    },
    {
      value: "Laotian",
      display: "Laotian"
    },
    {
      value: "Latvian",
      display: "Latvian"
    },
    {
      value: "Lebanese",
      display: "Lebanese"
    },
    {
      value: "Liberian",
      display: "Liberian"
    },
    {
      value: "Libyan",
      display: "Libyan"
    },
    {
      value: "Liechtensteiner",
      display: "Liechtensteiner"
    },
    {
      value: "Lithuanian",
      display: "Lithuanian"
    },
    {
      value: "Luxembourgish",
      display: "Luxembourgish"
    },
    {
      value: "Macedonian",
      display: "Macedonian"
    },
    {
      value: "Malagasy",
      display: "Malagasy"
    },
    {
      value: "Malawian",
      display: "Malawian"
    },
    {
      value: "Malaysian",
      display: "Malaysian"
    },
    {
      value: "Maldivan",
      display: "Maldivan"
    },
    {
      value: "Malian",
      display: "Malian"
    },
    {
      value: "Maltese",
      display: "Maltese"
    },
    {
      value: "Marshallese",
      display: "Marshallese"
    },
    {
      value: "Mauritanian",
      display: "Mauritanian"
    },
    {
      value: "Mauritian",
      display: "Mauritian"
    },
    {
      value: "Mexican",
      display: "Mexican"
    },
    {
      value: "Micronesian",
      display: "Micronesian"
    },
    {
      value: "Moldovan",
      display: "Moldovan"
    },
    {
      value: "Monegasque",
      display: "Monegasque"
    },
    {
      value: "Mongolian",
      display: "Mongolian"
    },
    {
      value: "Montenegrin",
      display: "Montenegrin"
    },
    {
      value: "Moroccan",
      display: "Moroccan"
    },
    {
      value: "Mosotho",
      display: "Mosotho"
    },
    {
      value: "Motswana",
      display: "Motswana"
    },
    {
      value: "Mozambican",
      display: "Mozambican"
    },
    {
      value: "Namibian",
      display: "Namibian"
    },
    {
      value: "Nauruan",
      display: "Nauruan"
    },
    {
      value: "Nepalese",
      display: "Nepalese"
    },
    {
      value: "New Zealander",
      display: "New Zealander"
    },
    {
      value: "Ni-Vanuatu",
      display: "Ni-Vanuatu"
    },
    {
      value: "Nicaraguan",
      display: "Nicaraguan"
    },
    {
      value: "Nigerian",
      display: "Nigerian"
    },
    {
      value: "Nigerien",
      display: "Nigerien"
    },
    {
      value: "North Korean",
      display: "North Korean"
    },
    {
      value: "Northern Irish",
      display: "Northern Irish"
    },
    {
      value: "Norwegian",
      display: "Norwegian"
    },
    {
      value: "Omani",
      display: "Omani"
    },
    {
      value: "Pakistani",
      display: "Pakistani"
    },
    {
      value: "Palauan",
      display: "Palauan"
    },
    {
      value: "Palestinian",
      display: "Palestinian"
    },
    {
      value: "Panamanian",
      display: "Panamanian"
    },
    {
      value: "Papua New Guinean",
      display: "Papua New Guinean"
    },
    {
      value: "Paraguayan",
      display: "Paraguayan"
    },
    {
      value: "Peruvian",
      display: "Peruvian"
    },
    {
      value: "Polish",
      display: "Polish"
    },
    {
      value: "Portuguese",
      display: "Portuguese"
    },
    {
      value: "Qatari",
      display: "Qatari"
    },
    {
      value: "Romanian",
      display: "Romanian"
    },
    {
      value: "Russian",
      display: "Russian"
    },
    {
      value: "Rwandan",
      display: "Rwandan"
    },
    {
      value: "Saint Lucian",
      display: "Saint Lucian"
    },
    {
      value: "Salvadoran",
      display: "Salvadoran"
    },
    {
      value: "Samoan",
      display: "Samoan"
    },
    {
      value: "San Marinese",
      display: "San Marinese"
    },
    {
      value: "Sao Tomean",
      display: "Sao Tomean"
    },
    {
      value: "Saudi",
      display: "Saudi"
    },
    {
      value: "Scottish",
      display: "Scottish"
    },
    {
      value: "Senegalese",
      display: "Senegalese"
    },
    {
      value: "Serbian",
      display: "Serbian"
    },
    {
      value: "Seychellois",
      display: "Seychellois"
    },
    {
      value: "Sierra Leonean",
      display: "Sierra Leonean"
    },
    {
      value: "Singaporean",
      display: "Singaporean"
    },
    {
      value: "Slovakian",
      display: "Slovakian"
    },
    {
      value: "Slovenian",
      display: "Slovenian"
    },
    {
      value: "Solomon Islander",
      display: "Solomon Islander"
    },
    {
      value: "Somali",
      display: "Somali"
    },
    {
      value: "South African",
      display: "South African"
    },
    {
      value: "South Korean",
      display: "South Korean"
    },
    {
      value: "South Sudanese",
      display: "South Sudanese"
    },
    {
      value: "Spanish",
      display: "Spanish"
    },
    {
      value: "Sri Lankan",
      display: "Sri Lankan"
    },
    {
      value: "Sudanese",
      display: "Sudanese"
    },
    {
      value: "Surinamer",
      display: "Surinamer"
    },
    {
      value: "Swazi",
      display: "Swazi"
    },
    {
      value: "Swedish",
      display: "Swedish"
    },
    {
      value: "Swiss",
      display: "Swiss"
    },
    {
      value: "Syrian",
      display: "Syrian"
    },
    {
      value: "Tajikistani",
      display: "Tajikistani"
    },
    {
      value: "Tanzanian",
      display: "Tanzanian"
    },
    {
      value: "Thai",
      display: "Thai"
    },
    {
      value: "Togolese",
      display: "Togolese"
    },
    {
      value: "Tongan",
      display: "Tongan"
    },
    {
      value: "Trinidadian Tobagonian",
      display: "Trinidadian Tobagonian"
    },
    {
      value: "Tunisian",
      display: "Tunisian"
    },
    {
      value: "Turkish",
      display: "Turkish"
    },
    {
      value: "Turkmen",
      display: "Turkmen"
    },
    {
      value: "Tuvaluan",
      display: "Tuvaluan"
    },
    {
      value: "Ugandan",
      display: "Ugandan"
    },
    {
      value: "Ukrainian",
      display: "Ukrainian"
    },
    {
      value: "Uruguayan",
      display: "Uruguayan"
    },
    {
      value: "Uzbekistani",
      display: "Uzbekistani"
    },
    {
      value: "Venezuelan",
      display: "Venezuelan"
    },
    {
      value: "Vietnamese",
      display: "Vietnamese"
    },
    {
      value: "Welsh",
      display: "Welsh"
    },
    {
      value: "Yemeni",
      display: "Yemeni"
    },
    {
      value: "Zambian",
      display: "Zambian"
    },
    {
      value: "Zimbabwean",
      display: "Zimbabwean"
    }
  ],
  shot: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Head portrait",
      display: "Head portrait"
    },
    {
      value: "Head and shoulders portrait",
      display: "Head and shoulders portrait"
    },
    {
      value: "Half-length portrait",
      display: "Half-length portrait"
    },
    {
      value: "Full-length portrait",
      display: "Full-length portrait"
    },
    {
      value: "Face",
      display: "Face"
    },
    {
      value: "Portrait",
      display: "Portrait"
    },
    {
      value: "Full body",
      display: "Full body"
    },
    {
      value: "Close-up",
      display: "Close-up"
    }
  ],
  style: [
    {
      value: "-",
      display: "Select Option"
    },
    {
      value: "Agfa Vista",
      display: "Agfa Vista"
    },
    {
      value: "Astia",
      display: "Astia"
    },
    {
      value: "Aurora Borealis",
      display: "Aurora Borealis"
    },
    {
      value: "Black and White Photography",
      display: "Black and White Photography"
    },
    {
      value: "Bleach Bypass",
      display: "Bleach Bypass"
    },
    {
      value: "Charcoal Sketch",
      display: "Charcoal Sketch"
    },
    {
      value: "CineStyle",
      display: "CineStyle"
    },
    {
      value: "City Lights",
      display: "City Lights"
    },
    {
      value: "Classic Chrome",
      display: "Classic Chrome"
    },
    {
      value: "Cool Blue Tint",
      display: "Cool Blue Tint"
    },
    {
      value: "Cross Hatch",
      display: "Cross Hatch"
    },
    {
      value: "Cross Process",
      display: "Cross Process"
    },
    {
      value: "Cyanotype",
      display: "Cyanotype"
    },
    {
      value: "Day to Night",
      display: "Day to Night"
    },
    {
      value: "Dreamy Desaturation",
      display: "Dreamy Desaturation"
    },
    {
      value: "Duotone",
      display: "Duotone"
    },
    {
      value: "Ektachrome",
      display: "Ektachrome"
    },
    {
      value: "Faded Glory",
      display: "Faded Glory"
    },
    {
      value: "Fashion Photography",
      display: "Fashion Photography"
    },
    {
      value: "Golden Hour Glow",
      display: "Golden Hour Glow"
    },
    {
      value: "Graphic Novel Style",
      display: "Graphic Novel Style"
    },
    {
      value: "Gritty Contrast",
      display: "Gritty Contrast"
    },
    {
      value: "HDR Look",
      display: "HDR Look"
    },
    {
      value: "HDR Photography",
      display: "HDR Photography"
    },
    {
      value: "High Key",
      display: "High Key"
    },
    {
      value: "Holga",
      display: "Holga"
    },
    {
      value: "Infrared Effect",
      display: "Infrared Effect"
    },
    {
      value: "Kodachrome",
      display: "Kodachrome"
    },
    {
      value: "Lomo",
      display: "Lomo"
    },
    {
      value: "Lomography",
      display: "Lomography"
    },
    {
      value: "Low Key",
      display: "Low Key"
    },
    {
      value: "Mars Red Landscape",
      display: "Mars Red Landscape"
    },
    {
      value: "Matte Finish",
      display: "Matte Finish"
    },
    {
      value: "Midnight Cool",
      display: "Midnight Cool"
    },
    {
      value: "Minimalist Photography",
      display: "Minimalist Photography"
    },
    {
      value: "Monochromatic Photography",
      display: "Monochromatic Photography"
    },
    {
      value: "Muted Colors",
      display: "Muted Colors"
    },
    {
      value: "Nature Photography",
      display: "Nature Photography"
    },
    {
      value: "Neon Glow",
      display: "Neon Glow"
    },
    {
      value: "Oil Painting Effect",
      display: "Oil Painting Effect"
    },
    {
      value: "Orton Effect",
      display: "Orton Effect"
    },
    {
      value: "Pastel Hues",
      display: "Pastel Hues"
    },
    {
      value: "Pastel Pop",
      display: "Pastel Pop"
    },
    {
      value: "Polaroid",
      display: "Polaroid"
    },
    {
      value: "Polaroid Photography",
      display: "Polaroid Photography"
    },
    {
      value: "Pop Art Colors",
      display: "Pop Art Colors"
    },
    {
      value: "Provia",
      display: "Provia"
    },
    {
      value: "Sepia Photography",
      display: "Sepia Photography"
    },
    {
      value: "Sepia Tone",
      display: "Sepia Tone"
    },
    {
      value: "Smoke Art Photography",
      display: "Smoke Art Photography"
    },
    {
      value: "Solarization",
      display: "Solarization"
    },
    {
      value: "Split Toning",
      display: "Split Toning"
    },
    {
      value: "Street Photography",
      display: "Street Photography"
    },
    {
      value: "Sunset Warmth",
      display: "Sunset Warmth"
    },
    {
      value: "Surreal Photography",
      display: "Surreal Photography"
    },
    {
      value: "Tri-tone",
      display: "Tri-tone"
    },
    {
      value: "Twilight Blue",
      display: "Twilight Blue"
    },
    {
      value: "Underwater Photography",
      display: "Underwater Photography"
    },
    {
      value: "Urban Exploration (Urbex)",
      display: "Urban Exploration (Urbex)"
    },
    {
      value: "Velvia",
      display: "Velvia"
    },
    {
      value: "Vibrant Colors",
      display: "Vibrant Colors"
    },
    {
      value: "Vintage Fade",
      display: "Vintage Fade"
    },
    {
      value: "Vintage Photography",
      display: "Vintage Photography"
    },
    {
      value: "Warm Orange Glow",
      display: "Warm Orange Glow"
    },
    {
      value: "Watercolor Effect",
      display: "Watercolor Effect"
    }
  ],
}

export default presetValues
