import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";
import DefaultImageCard from "../GeneratedImageCard/DefaultImageCard";
import {Map} from "immutable";

function GeneratedImagesFeed({
  workflowClientRef,
  workflowsInitialisationState,
  definition,
  generatedImages,
  setGeneratedImages,
  hasMore,
  setHasMore
}) {
  function sortedRecentFirst(workflows) {
    return workflows.sort(([key1, workflow1], [key2, workflow2]) =>
        workflow2.requestedAt.localeCompare(workflow1.requestedAt)
    )
  }

  const fetchMoreImages = () => {
    const sortedCurrent = sortedRecentFirst(
        Array.from(generatedImages.entries())
    )
    let beforeParam = null
    if (sortedCurrent.length > 0) {
      beforeParam = sortedCurrent[sortedCurrent.length - 1][0]
    }
    workflowClientRef.current.requestActiveWorkflows(definition.type,
        definition.variant, 20, beforeParam)
    .then(response => {

      const additionalWorkflows = response.data.workflows;

      const mapToMerge = Map(
          additionalWorkflows.map(workflow => [workflow.workflowId, workflow]))

      setGeneratedImages(
          prev => prev.merge(mapToMerge)
      )

      let hasMore = response.data.hasMore;
      setHasMore(hasMore)
    })
  };

  let latestRequestGrid = <></>
  if (!generatedImages.isEmpty()) {
    let latestWorkflow = sortedRecentFirst(
        Array.from(generatedImages.entries()))[0][1]
    let arrayOfSingleImageArrays = [[]];
    if (latestWorkflow.currentState === "COMPLETED") {
      arrayOfSingleImageArrays = latestWorkflow.outputs["object-storage-key"]
      .map(image => [image]);
    }

    const latestRequestedAt = new Date(
        Date.parse(latestWorkflow.requestedAt)).toLocaleString();

    const latestDescription = latestWorkflow.inputs["prompt"]

    let latestRequestDetails = (
        <MDBox>
          {latestDescription && (
              <MDBox>
                <MDTypography variant="h5" fontWeight="light" color="text">
                  {latestDescription}
                </MDTypography>
              </MDBox>
          )}
          <MDBox>
            <MDTypography variant="button" fontWeight="light" color="text">
              {latestRequestedAt}
            </MDTypography>
          </MDBox>
        </MDBox>
    )

    latestRequestGrid = (
        <>
          <Grid item xs={12}>
            {latestRequestDetails}
          </Grid>
          {arrayOfSingleImageArrays.map((images, index) =>
              <Grid key={`latest-grid-${index}`} item xs={12} md={6} xl={3}>
                <DefaultImageCard
                    key={`latest-image-card-${index}`}
                    images={images}
                    requestedAt={latestWorkflow.requestedAt}
                    showTimestamp="false"
                    index={index}
                    state={latestWorkflow.currentState}
                />
              </Grid>
          )}
        </>
    )
  }

  let historicRequestsGrid = null
  if (generatedImages.size > 1) {
    historicRequestsGrid = sortedRecentFirst(
        Array.from(generatedImages.entries())
    ).slice(1).map(
        ([key, workflow]) => {
          let images = [];
          if (workflow.currentState === "COMPLETED") {
            images = workflow.outputs["object-storage-key"];
          }
          return (
              <Grid key={key} item xs={12} md={6}
                    xl={3}>
                <DefaultImageCard
                    images={images}
                    state={workflow.currentState}
                    requestedAt={workflow.requestedAt}
                    description={workflow.inputs["prompt"]}
                />
              </Grid>
          )
        }
    )
  }

  return (<>
    <MDBox pt={1} px={2} lineHeight={1.25}>
      <MDTypography variant="h4" fontWeight="light">
        Latest request
      </MDTypography>
    </MDBox>
    {workflowsInitialisationState.message.length > 0 && (
        <MDBox p={2}>
          <MDTypography
              variant="button" fontWeight="light"
              color={workflowsInitialisationState.error
                  ? "error"
                  : "text"}>{workflowsInitialisationState.message}</MDTypography>
        </MDBox>
    )}
    <MDBox p={2}>
      <Grid container spacing={6} justifyContent="flex-start">
        {latestRequestGrid}
      </Grid>
    </MDBox>
    {historicRequestsGrid && (<>
          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h4" fontWeight="light">
              Previous requests
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <InfiniteScroll
                dataLength={generatedImages.size}
                next={fetchMoreImages}
                hasMore={hasMore}
                loader={<MDBox><MDTypography color="text">Fetching
                  more images. Please wait...</MDTypography></MDBox>}>
              <Grid container spacing={6}
                    justifyContent="flex-start">
                {historicRequestsGrid}
              </Grid>
            </InfiniteScroll>
          </MDBox>
        </>
    )}
  </>)
}

export default GeneratedImagesFeed
