const defaultValues = {
  shot_type: "Full body",
  shot_weight: 1.02,
  gender: "Woman",
  androgynous: 1.35,
  age: 29,
  nationality_1: "Austrian",
  nationality_2: "-",
  nationality_mix: 0.5,
  body_type: "-",
  body_type_weight: 1.37,
  model_pose: "High Fashion Pose",
  clothes: "Club Dress",
  eyes_color: "Blue",
  eyes_shape: "-",
  lips_color: "-",
  lips_shape: "-",
  facial_expression: "Bored",
  facial_expression_weight: 0,
  face_shape: "-",
  face_shape_weight: 0,
  facial_asymmetry: 0,
  hair_style: "Wavy bob",
  hair_color: "Auburn",
  hair_length: "-",
  disheveled: 0,
  makeup: "-",
  beard: "-",
  natural_skin: 1.37,
  bare_face: 0,
  washed_face: 0,
  dried_face: 0,
  skin_details: 1.24,
  skin_pores: 1.13,
  dimples: 0,
  wrinkles: 0,
  freckles: 0,
  moles: 0,
  skin_imperfections: 0.21,
  skin_acne: 0,
  tanned_skin: 0,
  eyes_details: 0.97,
  iris_details: 1,
  circular_iris: 1.01,
  circular_pupil: 1,
  light_type: "-",
  light_direction: "-",
  light_weight: 0.98,
  photorealism_improvement: "enable",
  prompt_start: "",
  prompt_additional: "",
  prompt_end: "",
  style_1: "-",
  style_1_weight: 1.89,
  style_2: "-",
  style_2_weight: 1.78
}

export default defaultValues
