/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "./components/Header";
import TextPromptGeneratorInputs from "./components/TextPromptGeneratorInputs";

// Data
// Images
import RequestHandler from "../../processing/RequestHandler";
import {useEffect, useRef, useState} from "react";
import NotificationsClient from "../../processing/NotificationsClient";
import {useAuth0} from "@auth0/auth0-react";
import WorkflowClient from "../../processing/WorkflowClient";
import {Map} from 'immutable';
import {useLocation} from "react-router-dom";
import GeneratedImagesFeed from "../../mycomponents/GeneratedImagesFeed";
import HumanModelGeneratorInputs from "./components/HumanModelGeneratorInputs";
import ImageStyleLikeImageInputs from "./components/ImageStyleLikeImageInputs";
import ObjectRemoverInputs from "./components/ObjectRemoverInputs";
import TokenBalanceClient from "../../processing/TokenBalanceClient";

function ImageGenerator({title, description, definition}) {

  const [generatedImages, setGeneratedImages] = useState(Map())

  const [hasMore, setHasMore] = useState(false);

  const [workflowsInitialisationState, setWorkflowsInitialisationState] = useState(
      {message: "Loading previously generated images...", error: false})

  const {getAccessTokenSilently} = useAuth0();

  const notificationsClientRef = useRef(
      new NotificationsClient(handleNotification,
          getAccessTokenSilently));

  const [currentBalance, setCurrentBalance] = useState(null)
  const [currentVariantCost, setCurrentVariantCost] = useState(null)

  const balanceClientRef = useRef(
      new TokenBalanceClient(getAccessTokenSilently)
  )

  function handleNotification(message, name) {
    const aNotification = JSON.parse(message.body)
    setWorkflowsInitialisationState({message: "", error: false})
    setGeneratedImages(
        prev => prev.set(aNotification.workflowId, aNotification)
    )
    if (aNotification.currentState === "COMPLETED" || aNotification.currentState
        === "FAILED" || aNotification.currentState === "CANCELLED") {
      notificationsClientRef.current.unsubscribe(
          `/topic/workflow/${aNotification.workflowId}`)
      balanceClientRef.current.requestCurrentBalance().then(
          response => {
            setCurrentBalance(response.data.available)
          }
      );
    }
  }

  const location = useLocation();

  useEffect(() => {
    setGeneratedImages(Map())
    setWorkflowsInitialisationState(
        {message: "Loading previously generated images...", error: false})

    workflowClientRef.current.requestActiveWorkflows(definition.type,
        definition.variant, 20, null)
    .then(response => {

      let workflows = response.data.workflows;
      let hasMore = response.data.hasMore;

      if (workflows.length === 0) {
        setWorkflowsInitialisationState(
            {
              message: "Nothing here yet. Go ahead and generate some images!",
              error: false
            })
      } else {
        setWorkflowsInitialisationState({message: "", error: false})
      }

      setGeneratedImages(
          Map(workflows.map(workflow => [workflow.workflowId, workflow]))
      )

      setHasMore(hasMore)

      return workflows.filter(
          workflow => workflow.currentState !== "COMPLETED"
              && workflow.currentState !== "FAILED"
              && workflow.currentState !== "CANCELLED")
    }).then(activeWorkflows =>
        activeWorkflows.forEach(workflow =>
            notificationsClientRef.current.subscribe(
                `/topic/workflow/${workflow.workflowId}`)
        )
    ).catch(e => setWorkflowsInitialisationState(
        {message: "Failed to initialise generator: " + e.message, error: true}
    ))

    setCurrentVariantCost(null)
    balanceClientRef.current.requestCurrentBalance().then(
        response => {
          setCurrentBalance(response.data.available)
        }
    ).catch(e => {
      setWorkflowsInitialisationState({
        message: "Failed to initialise generator: " + e.message,
        error: true
      })
    })

    balanceClientRef.current.costForGenerateImageVariant(definition.type, definition.variant).then(
        response => {
          setCurrentVariantCost(response.data)
        }
    ).catch(e => {
      setWorkflowsInitialisationState({
        message: "Failed to initialise generator: " + e.message,
        error: true
      })
    })
  }, [location]);

  const workflowClientRef = useRef(new WorkflowClient(getAccessTokenSilently))

  let inputsComponent = null

  if (definition.inputType === "text-prompts") {
    inputsComponent =
        (handleGenerateRequest, progressState) => (
            <MDBox mt={1} mb={1} p={2}>
              <TextPromptGeneratorInputs
                  requestHandler={handleGenerateRequest}
                  progressState={progressState}
                  definition={definition}
              />
            </MDBox>
        );
  } else if (definition.inputType === "human-model") {
    inputsComponent =
        (handleGenerateRequest, progressState) => (
            <MDBox mt={1} mb={1} p={2}>
              <HumanModelGeneratorInputs
                  requestHandler={handleGenerateRequest}
                  progressState={progressState}
                  definition={definition}
              />
            </MDBox>
        );
  } else if (definition.inputType === "image-style-like-image") {
    inputsComponent =
        (handleGenerateRequest, progressState) => (
            <MDBox mt={1} mb={1} p={2}>
              <ImageStyleLikeImageInputs
                  workflowClientRef={workflowClientRef}
                  requestHandler={handleGenerateRequest}
                  progressState={progressState}
                  definition={definition}
              />
            </MDBox>
        );
  } else if (definition.inputType === "object-remover") {
    inputsComponent =
        (handleGenerateRequest, progressState) => (
            <MDBox mt={1} mb={1} p={2}>
              <ObjectRemoverInputs
                  workflowClientRef={workflowClientRef}
                  requestHandler={handleGenerateRequest}
                  progressState={progressState}
                  definition={definition}
              />
            </MDBox>
        );
  }

  return (
      <DashboardLayout>
        <DashboardNavbar/>
        <MDBox mb={2}/>
        <Header title={title}
                description={description}
                currentBalance={currentBalance}
                currentVariantCost={currentVariantCost}>
          <RequestHandler notificationsClientRef={notificationsClientRef}
                          workflowClientRef={workflowClientRef}
                          children={inputsComponent}/>
          <GeneratedImagesFeed
              workflowClientRef={workflowClientRef}
              notificationsClientRef={notificationsClientRef}
              workflowsInitialisationState={workflowsInitialisationState}
              hasMore={hasMore}
              setHasMore={setHasMore}
              generatedImages={generatedImages}
              setGeneratedImages={setGeneratedImages}
              definition={definition}
          />
        </Header>
        <Footer/>
      </DashboardLayout>
  );
}

export default ImageGenerator;
