import MDBox from "../../components/MDBox";
import SaveImageButton from "../SaveImageButton";
import IconButton from "@mui/material/IconButton";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import {Modal} from "@mui/material";
import moment from "moment/moment";
import {useState} from "react";

function ImageControls({ image, requestedAt, description, index }) {

  const imageBaseUrl = process.env.REACT_APP_API_URL + "/images/"

  const utcDate = new Date(Date.parse(requestedAt));
  const formattedDate = moment(utcDate).format("YYYY-MM-DD-HHmmss");

  const [modalOpen, setModalOpen] = useState(false);

  const handleZoomClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
      <MDBox sx={{
        position: 'absolute',
        top: 10,
        right: 10,
        display: "flex",
        flexDirection: "row",
        gap: 1
      }}>
        <MDBox>
          <SaveImageButton
              filename={`eikona-image-${formattedDate}-${index}.png`}
              imageUrl={imageBaseUrl + image}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: 2,
                padding: 0.5,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }
              }}
          />
        </MDBox>
        <MDBox>
          <IconButton
              aria-label="zoom"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: 2,
                padding: 0.5,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }
              }}
              onClick={handleZoomClick}
          >
            <ZoomOutMapIcon/>
          </IconButton>
          <Modal open={modalOpen} onClose={handleModalClose}>
            <MDBox onClick={handleModalClose}>
              <img
                  src={imageBaseUrl + image}
                  alt={description}
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'contain'
                  }}
              />
            </MDBox>
          </Modal>
        </MDBox>
      </MDBox>
  )
}

export default ImageControls