import React, {useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import 'myassets/spinner.css'

function ImageUploader({
  workflowClientRef,
  setUploadedFile,
  progressState
}) {

  const [file, setFile] = useState(null)

  const [fileUploading, setFileUploading] = useState(false)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null)

  const previousProgressState = useRef();

  useEffect(() => {
    if (previousProgressState.current && previousProgressState.current.running
        && !progressState.running && !progressState.error) {
      setFile(null)
      setUploadErrorMessage(null)
      setFileUploading(false)
      setFileUploaded(false)
      setUploadedFile(null)
    }
    previousProgressState.current = progressState
  }, [progressState]);

  const onDrop = acceptedFiles => {
    // Only accept single PNG image
    const newFile = acceptedFiles[0];
    if (newFile && (newFile.type === 'image/png' || newFile.type
        === 'image/jpeg')) {
      setUploadErrorMessage(null)
      setFileUploading(false)
      setFileUploaded(false)
      setFile(newFile);
    }
  };

  const handleUpload = () => {
    const upload = async () => {
      workflowClientRef.current.uploadFile(file).then(response => {
            setUploadedFile(response.data)
            setFileUploading(false)
            setFileUploaded(true)
          }
      ).catch(e => {
        setFileUploading(false)
        setFileUploaded(false)
        setUploadErrorMessage("Failed to upload file: " + e.message)
      })
    }
    setFileUploading(true)
    upload()
  };

  const {getRootProps, getInputProps} = useDropzone(
      {
        accept: {
          'image/png': ['.png'],
          'image/jpeg': ['.jpg', '.jpeg']
        }, onDrop
      });

  return (
      <MDBox>
        <MDBox {...getRootProps(
            {className: `dropzone ${file ? 'dropped' : ''}`})}>
          <input {...getInputProps()} />
          {file ? (
              <CardMedia
                  src={URL.createObjectURL(file)}
                  component="img"
                  title="Preview"
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    margin: 0,
                    aspectRatio: 1,
                    boxShadow: ({boxShadows: {md}}) => md,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
              />
          ) : (
              <Card
                  sx={{
                    maxWidth: "100%",
                    margin: 0,
                    border: 1,
                    borderColor: "silver",
                    aspectRatio: 1,
                    boxShadow: ({boxShadows: {md}}) => md,
                    objectFit: "cover",
                    objectPosition: "center"
                  }}
              >
                <MDBox
                    display="flex"
                    p={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      aspectRatio: 1
                    }}
                >
                  <MDBox p={3} sx={{display: "flex", alignItems: "center"}}>
                    <MDBox mt={0.5} p={1}><Icon>mms</Icon></MDBox>
                    <MDTypography variant="button">Drag & drop a PNG or JPEG
                      image here, or click to select</MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
          )}
        </MDBox>
        <MDBox mt={1} sx={{display: "flex", justifyContent: "center"}}>
          <MDButton
              sx={{}}
              disabled={!file || progressState.running || fileUploading
                  || fileUploaded}
              color="dark"
              onClick={handleUpload}
          >
            {fileUploading && (
                <MDBox mr={1} className="small-spinner"></MDBox>)}
            {!fileUploaded && !fileUploading && ("Upload")}
            {fileUploading && !fileUploaded && ("Uploading")}
            {fileUploaded && ("Uploaded")}
          </MDButton>
        </MDBox>
        {uploadErrorMessage && (<MDBox mt={1}><MDTypography variant="button"
                                                            color="error">{uploadErrorMessage}</MDTypography></MDBox>)}
      </MDBox>
  );
}

export default ImageUploader;
