/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useRef, useState} from "react";

// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import ImageWithMaskUploader from "../ImageWithMaskUploader";

function ObjectRemoverInputs({
  workflowClientRef,
  requestHandler,
  progressState,
  definition
}) {

  const [uploadedReferenceFile, setUploadedReferenceFile] = useState(null)
  const [uploadedMaskFile, setUploadedMaskFile] = useState(null)

  const formRef = useRef(null);

  useEffect(() => {
    if (uploadedReferenceFile && uploadedMaskFile) {
      formRef.current.requestSubmit()
    }
  }, [uploadedReferenceFile, uploadedMaskFile]);

  async function handleSubmit(event) {
    event.preventDefault();
    const reference_img = uploadedReferenceFile
    const mask_img = uploadedMaskFile
    requestHandler(definition.type, definition.variant, {
      input_img: reference_img,
      input_mask: mask_img,
      mask_threshold: 250, // TODO: user input
      blur_strength: 8 // TODO: user input
    })
  }

  return (
      <form ref={formRef} onSubmit={handleSubmit}>
        <MDBox><MDTypography variant="button">Draw over the object to be
          removed. Try mouse wheel to zoom and right click to pan image. CTRL+Z
          to Undo.</MDTypography></MDBox>
        <MDBox>
          <ImageWithMaskUploader workflowClientRef={workflowClientRef}
                                 setUploadedReferenceFile={setUploadedReferenceFile}
                                 setUploadedMaskFile={setUploadedMaskFile}
                                 progressState={progressState}
          />
        </MDBox>
        <MDBox>
          <MDTypography variant="button" fontWeight="light"
                        color={progressState.error ? "error" : "text"}>
            {progressState.message}
          </MDTypography>
        </MDBox>
      </form>
  );
}

export default ObjectRemoverInputs;
