/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

import '../../../myassets/spinner.css'
import Icon from "@mui/material/Icon";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ImageControls
  from "../../ImageControls";

function DefaultImageCard({images, state, requestedAt, description, index = 0, showTimestamp = true}) {
  const imageBaseUrl = process.env.REACT_APP_API_URL + "/images/"

  // TODO: a bit of backwards compatibility - remove when history burned / migrated
  if (images && !Array.isArray(images)) {
    images = [images]
  }

  const localRequestedTime = requestedAt ? new Date(Date.parse(requestedAt)).toLocaleString() : null;

  const hasImages = images && images.length > 0

  const responsive = {
    allDisplays: {
      breakpoint: {max: 4000, min: 0},
      items: 1
    }
  };

  return (
      <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: 1,
            boxShadow: "none",
            overflow: "visible",
          }}
      >
        <MDBox position="relative" width="100%" shadow="xl"
               borderRadius="xl">

          {hasImages && images.length > 1 &&
              <Carousel responsive={responsive}>
                {images.map((image, index) =>
                    <MDBox key={`${image}-box`}>
                      <CardMedia
                          src={imageBaseUrl + image}
                          component="img"
                          title={description}
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            margin: 0,
                            aspectRatio: 1,
                            boxShadow: ({boxShadows: {md}}) => md,
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                      />
                      <ImageControls
                          image={image}
                          requestedAt={requestedAt}
                          description={description}
                          index={index}
                      />
                    </MDBox>
                )}
              </Carousel>
          }

          {hasImages && images.length == 1 &&
              <MDBox>
                <CardMedia
                    src={imageBaseUrl + images[0]}
                    component="img"
                    title={description}
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      margin: 0,
                      aspectRatio: 1,
                      boxShadow: ({boxShadows: {md}}) => md,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                />
                <ImageControls
                    image={images[0]}
                    requestedAt={requestedAt}
                    description={description}
                    index={index}
                />
              </MDBox>
          }

          {!hasImages && (
              <Card
                  sx={{
                    maxWidth: "100%",
                    margin: 0,
                    border: 1,
                    borderColor: "silver",
                    aspectRatio: 1,
                    boxShadow: ({boxShadows: {md}}) => md,
                    objectFit: "cover",
                    objectPosition: "center"
                  }}
              >
                <MDBox
                    display="flex"
                    p={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      aspectRatio: 1
                    }}
                >
                  {state == "PENDING" && (<>
                    <MDBox className="spinner"></MDBox>
                    <MDBox p={3}>
                      <MDTypography
                          variant="button">Generating...</MDTypography>
                    </MDBox>
                  </>)}
                  {state == "FAILED" && (
                      <MDBox p={3} sx={{display: "flex", alignItems: "center"}}>
                        <MDBox mt={0.5} p={1}><Icon>error</Icon></MDBox>
                        <MDTypography variant="button">Generation
                          failed</MDTypography>
                      </MDBox>
                  )}
                  {state == "CANCELLED" && (
                      <MDBox p={3} sx={{display: "flex", alignItems: "center"}}>
                        <MDBox mt={0.5} p={1}><Icon>cancel</Icon></MDBox>
                        <MDTypography variant="button">Cancelled</MDTypography>
                      </MDBox>
                  )}
                </MDBox>
              </Card>
          )}
        </MDBox>
        <MDBox mt={1} mx={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text"
                        textTransform="capitalize">
            <MDBox sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
              {images.length> 1 && (<MDBox sx={{whiteSpace: "nowrap"}}>Images: {images.length}</MDBox>)}
              {showTimestamp === true && localRequestedTime && (<MDBox
                  sx={{textAlign: "right"}}>{localRequestedTime}</MDBox>)}
            </MDBox>
          </MDTypography>
          {description && (
              <MDBox mb={3} lineHeight={0}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  {description}
                </MDTypography>
              </MDBox>
          )}
        </MDBox>
      </Card>
  )
}

// Typechecking props for the DefaultProjectCard
DefaultImageCard.propTypes = {
  requestedAt: PropTypes.string.isRequired
};

export default DefaultImageCard;
