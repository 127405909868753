/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Dashboard from "./layouts/dashboard";
import ImageGenerator from "./layouts/image-generator";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard/>,
  },
  {
    type: "divider",
    key: "divider-1"
  },
  {
    type: "title",
    icon: <Icon fontSize="small">mms</Icon>,
    key: "text-to-image-title",
    title: "Text to Image",
    needsAuth: true,
    requiredPerms: "generate:text2image"
  },
  {
    type: "collapse",
    name: "Fast Generation",
    key: "text-to-imagefast",
    icon: <Icon fontSize="small">mms</Icon>,
    route: "/text-to-image/fast",
    component: <ImageGenerator
        title="Text To Image : Fast Generator"
        description="Provide text description of desired output to generate images fast"
        definition={{
            inputType: "text-prompts",
            type: "text2image",
            variant: "fast"
          }}
    />,
    needsAuth: true,
    requiredPerms: "generate:text2image"
  },
  {
    type: "collapse",
    name: "Quality Generation",
    key: "text-to-imagequality",
    icon: <Icon fontSize="small">mms</Icon>,
    route: "/text-to-image/quality",
    component: <ImageGenerator
        title="Text To Image : Quality Generator"
        description="Provide text description of desired and undesired output to generate batch of images of higher quality at expense of time"
        definition={{
          inputType: "text-prompts",
          type: "text2image",
          variant: "quality"
        }}
    />,
    needsAuth: true,
    requiredPerms: "generate:text2image"
  },
  {
    type: "title",
    key: "studio-title",
    title: "Studio",
    needsAuth: true,
    requiredPerms: "generate:human_model"
  },
  {
    type: "collapse",
    name: "Model Photoshoot",
    key: "model-photoshoot",
    icon: <CameraAltIcon fontSize="small">CameraAlt</CameraAltIcon>,
    route: "/model-photoshoot",
    component: <ImageGenerator
        title="Model Photoshoot"
        description="Define parameters to control the outcome of a model photo session"
        definition={{
          inputType: "human-model",
          type: "studio",
          variant: "human-model"
        }}
    />,
    needsAuth: true,
    requiredPerms: "generate:human_model"
  },
  {
    type: "title",
    key: "styling-title",
    title: "Apply Style",
    needsAuth: true,
    requiredPerms: "generate:styling"
  },
  {
    type: "collapse",
    name: "Like Another Image",
    key: "styling-like-image",
    icon: <CameraAltIcon fontSize="small">CameraAlt</CameraAltIcon>,
    route: "/styling/like-image",
    component: <ImageGenerator
        title="Styling Like Another Image"
        description="Apply styling matching another image"
        definition={{
          inputType: "image-style-like-image",
          type: "styling",
          variant: "like-image"
        }}
    />,
    needsAuth: true,
    requiredPerms: "generate:styling"
  },
  {
    type: "title",
    key: "image-editing-title",
    title: "Image Editing",
    needsAuth: true,
    requiredPerms: "generate:editing"
  },
  {
    type: "collapse",
    name: "Object Remover",
    key: "object-remover",
    icon: <CameraAltIcon fontSize="small">CameraAlt</CameraAltIcon>,
    route: "/styling/object-remover",
    component: <ImageGenerator
        title="Object Remover"
        description="Remove object from exsting image"
        definition={{
          inputType: "object-remover",
          type: "editing",
          variant: "object-remover"
        }}
    />,
    needsAuth: true,
    requiredPerms: "generate:editing"
  }
];

export default routes;
