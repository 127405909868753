import {useState} from 'react';

function RequestHandler({workflowClientRef, notificationsClientRef, children}) {

  const [progressState, setProgressState] = useState(
      {
        message: "",
        running: false,
        error: false
      }
  )

  async function handleGenerateRequest(workflowType, variant, inputs) {
    setProgressState({
          message: "Submitting request...",
          running: true,
          error: false
        }
    );

    try {
      let workflowId = await workflowClientRef.current.requestGenerateImage(
          workflowType,
          variant,
          inputs
      );
      notificationsClientRef.current.subscribe(`/topic/workflow/${workflowId}`)
      setProgressState({
        message: "Request submitted.",
        running: false,
        error: false
      })
    } catch (e) {
      setProgressState(
          {
            message: "Request not submitted. Error details: " + e,
            running: false,
            error: true
          }
      );
    }
  }

  // Call the children function with the necessary data
  return children(handleGenerateRequest, progressState);
}

export default RequestHandler;
