import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

import {MaterialUIControllerProvider} from "context";
import {Auth0Provider} from "@auth0/auth0-react";

const container = document.getElementById("app");
const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
      <Auth0Provider
          domain="dev-3nsp4yadhj3drlxd.us.auth0.com"
          clientId="SYv4Ig4fbVRlbhegNWLKqPpPaXM61qI8"
          authorizationParams={{
            redirect_uri: window.location.origin + "/dashboard",
            audience: "https://michael.adamik.info/api/",
            scope: "openid profile email"
          }}
      >
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <App/>
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
