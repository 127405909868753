/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect, useMemo} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import {useAuth0} from "@auth0/auth0-react";
import {jwtDecode} from "jwt-decode";

import ReactGA from "react-ga4";

ReactGA.initialize('G-64S30SWPHX'); // Replace with your tracking ID

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const {pathname} = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch,
      !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  const [permissions, setPermissions] = useState([])

  const {
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      setPermissions([]);
      return
    }
    const fetchToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const decodedToken = jwtDecode(accessToken);
      setPermissions(decodedToken.permissions);
      ReactGA.set({ userId: decodedToken.custom_email_claim });
    }
    fetchToken()
  }, [isAuthenticated, getAccessTokenSilently])

  const getRoutes = (allRoutes) =>
      allRoutes
      .map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route exact path={route.route} element={route.component}
                        key={route.key}/>;
        }

        return null;
      });

  const configsButton = (
      <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3.25rem"
          height="3.25rem"
          bgColor="white"
          shadow="sm"
          borderRadius="50%"
          position="fixed"
          right="2rem"
          bottom="2rem"
          zIndex={99}
          color="dark"
          sx={{cursor: "pointer"}}
          onClick={handleConfiguratorOpen}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>
  );

  function visibleRoutes(routes) {
    return routes.filter((route) => !route.needsAuth || isAuthenticated && permissions.includes(route.requiredPerms));
  }

  return direction === "rtl" ? (
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline/>
          {layout === "dashboard" && (
              <>
                <Sidenav
                    color={sidenavColor}
                    brand={(transparentSidenav && !darkMode) || whiteSidenav
                        ? brandDark : brandWhite}
                    brandName="Eikona"
                    routes={visibleRoutes(routes)}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                />
                <Configurator/>
                {configsButton}
              </>
          )}
          {layout === "vr" && <Configurator/>}
          <Routes>
            {getRoutes(visibleRoutes(routes))}
            <Route path="*" element={<Navigate to="/dashboard"/>}/>
          </Routes>
        </ThemeProvider>
      </CacheProvider>
  ) : (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline/>
        {layout === "dashboard" && (
            <>
              <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav
                      ? brandDark : brandWhite}
                  brandName="Eikona"
                  routes={visibleRoutes(routes)}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
              />
              <Configurator/>
              {configsButton}
            </>
        )}
        {layout === "vr" && <Configurator/>}
        <Routes>
          {getRoutes(visibleRoutes(routes))}
          <Route path="*" element={<Navigate to="/dashboard"/>}/>
        </Routes>
      </ThemeProvider>
  );
}
