import React, {useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import 'myassets/spinner.css'
import {FabricJSCanvas, useFabricJSEditor} from "fabricjs-react";
import {fabric} from 'fabric';
import {useMediaQuery, useTheme} from "@mui/material";

function ImageWithMaskUploader({
  workflowClientRef,
  setUploadedReferenceFile,
  setUploadedMaskFile,
  progressState
}) {

  const [referenceFile, setReferenceFile] = useState(null)

  const [referenceFileUploading, setReferenceFileUploading] = useState(false)
  const [referenceFileUploaded, setReferenceFileUploaded] = useState(false)
  const [maskFileUploading, setMaskFileUploading] = useState(false)
  const [maskFileUploaded, setMaskFileUploaded] = useState(false)

  const [uploadErrorMessage, setUploadErrorMessage] = useState("")

  const previousProgressState = useRef();

  // const editorCanvasMaxWidth = 1024
  // const editorCanvasMaxHeight = 1024
  const brushSize = 20

  useEffect(() => {
    if (previousProgressState.current && previousProgressState.current.running
        && !progressState.running && !progressState.error) {
      setReferenceFile(null)
      setUploadErrorMessage("")
      setReferenceFileUploading(false)
      setReferenceFileUploaded(false)
      setMaskFileUploaded(false)
      setMaskFileUploading(false)
      setUploadedReferenceFile(null)
    }
    previousProgressState.current = progressState
  }, [progressState]);

  const onDrop = acceptedFiles => {
    // Only accept single PNG image
    const newFile = acceptedFiles[0];
    if (newFile && (newFile.type === 'image/png' || newFile.type
        === 'image/jpeg')) {
      setUploadErrorMessage("")
      setReferenceFileUploading(false)
      setReferenceFileUploaded(false)
      setMaskFileUploaded(false)
      setMaskFileUploading(false)
      setReferenceFile(newFile);
    }
  };

  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));

  const canvasParentRef = useRef(null);

  function resetEditor(canvas, referenceFile = null) {
    var emptyHeight = 0
    var emptyWidth = canvasParentRef.current.offsetWidth

    if (isBelowSm) {
      emptyHeight = 300
    } else if (isBelowMd) {
      emptyHeight = 512
    } else {
      emptyHeight = 1024
    }

    if (referenceFile) {
      canvas.setHeight(
          referenceFile.height > emptyHeight ? emptyHeight
              : referenceFile.height)
      canvas.setWidth(
          referenceFile.width > emptyWidth ? emptyWidth
              : referenceFile.width)
    } else {
      canvas.getObjects().forEach(obj => {
        canvas.remove(obj);
      });

      canvas.setWidth(emptyWidth)
      canvas.setHeight(emptyHeight)
    }

    canvas.fireRightClick = true
    canvas.stopContextMenu = true

    function undo() {
      const objects = canvas.getObjects()
      if (objects.length > 0) {
        canvas.remove(objects[objects.length - 1]);
      }
    }

    // Attach undo function to a button or keyboard shortcut
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key === 'z') {
        undo();
      }
    });

    // Zoom
    canvas.on('mouse:wheel', (opt) => {
      const delta = opt.e.deltaY;
      let zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      zoom = Math.min(Math.max(0.01, zoom), 20); // Limit zoom level
      canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);

      canvas.freeDrawingBrush.width = brushSize / zoom;

      opt.e.preventDefault();
      opt.e.stopPropagation();
    })

    // Panning
    canvas.on('mouse:down', function (opt) {
      var evt = opt.e;
      if (evt.button === 2) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });
    canvas.on('mouse:move', function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });
    canvas.on('mouse:up', function (opt) {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      const evt = opt.e
      if (evt.button === 2) {
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      }
    });
  }

  useEffect(() => {
    if (referenceFile) {
      const fileUrl = URL.createObjectURL(referenceFile)
      loadImage(fileUrl)
    } else {
      if (editor && editor.canvas) {
        resetEditor(editor.canvas)
      }
    }
  }, [referenceFile]);

  async function convertObjectUrlToFile(objectUrl, fileName, mimeType) {
    const response = await fetch(objectUrl);
    const blob = await response.blob();
    return new File([blob], fileName, {type: mimeType});
  }

  const handleUpload = () => {
    const maskImageObjectUrl = getMaskImage()

    const upload = async () => {

      const maskFile = await convertObjectUrlToFile(maskImageObjectUrl,
          'mask.png', 'image/png')

      workflowClientRef.current.uploadFile(maskFile).then(response => {
            setUploadedMaskFile(response.data)
            setMaskFileUploading(false)
            setMaskFileUploaded(true)
          }
      ).catch(e => {
        setMaskFileUploading(false)
        setMaskFileUploaded(false)
        setUploadErrorMessage(
            (current) =>
                current + " Failed to upload mask file: " + e.message + ".")
      })

      workflowClientRef.current.uploadFile(referenceFile).then(response => {
            setUploadedReferenceFile(response.data)
            setReferenceFileUploading(false)
            setReferenceFileUploaded(true)
          }
      ).catch(e => {
        setReferenceFileUploading(false)
        setReferenceFileUploaded(false)
        setUploadErrorMessage(
            (current) => current + " Failed to upload reference file: "
                + e.message + ".")
      })

    }
    setReferenceFileUploading(true)
    upload()
  };

  const {getRootProps, getInputProps} = useDropzone(
      {
        accept: {
          'image/png': ['.png'],
          'image/jpeg': ['.jpg', '.jpeg']
        }, onDrop
      });

  const {editor, onReady} = useFabricJSEditor();

  const loadImage = (imageUrl) => {
    fabric.Image.fromURL(imageUrl, (img) => {
      if (editor) {
        const canvas = editor.canvas;

        canvas.setHeight(
            img.height > canvas.height ? canvas.height : img.height)
        canvas.setWidth(img.width > canvas.width ? canvas.width : img.width)

        const scaleXToFit = canvas.width / img.width;
        const scaleYToFit = canvas.height / img.height;
        const scaleToFit = Math.min(scaleXToFit, scaleYToFit);

        // Set image as background and resize to fit
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));

        // // Zoom and center the image on load
        canvas.setZoom(scaleToFit);
        const centerX = (canvas.width - img.width * scaleToFit) / 2;
        const centerY = (canvas.height - img.height * scaleToFit) / 2;
        canvas.viewportTransform = [scaleToFit, 0, 0, scaleToFit, centerX,
          centerY];

        // // Set up drawing tools (e.g., brush, pencil)
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush.width = brushSize
        canvas.freeDrawingBrush.color = 'rgba(255,0,0,0.3)'
        canvas.isDrawingMode = true;

        canvas.renderAll();
      }
    });
  };

  const onCanvasReady = (canvas) => {
    resetEditor(canvas, referenceFile);
    onReady(canvas)
  }

  const getMaskImage = () => {
    // Clone the mask layer to avoid affecting the original
    const maskLayerElements = editor.canvas.getObjects().filter(
        obj => obj !== editor.canvas.backgroundImage).map(
        element => fabric.util.object.clone(element))

    const tempCanvas = new fabric.StaticCanvas(null, {
      width: editor.canvas.backgroundImage.width,
      height: editor.canvas.backgroundImage.height,
      backgroundColor: 'black'
    });

    maskLayerElements.forEach(maskLayer => {
          maskLayer.set({stroke: 'white'})
          tempCanvas.add(maskLayer);
        }
    )

    tempCanvas.renderAll();

    // Get the data URL of the mask image
    const maskDataURL = tempCanvas.toDataURL({format: 'png'});

    // Clean up the temporary canvas
    tempCanvas.dispose();

    return maskDataURL;
  };

  return (
      <MDBox>
        <input {...getInputProps()} />
        <MDBox sx={referenceFile ? {
          border: 1,
          boxShadow: ({boxShadows: {md}}) => md,
          borderColor: "silver",
          maxWidth: "1024px"
        } : {display: 'none'}}>
          <FabricJSCanvas onReady={onCanvasReady}/>
        </MDBox>
        {!referenceFile && (
            <Card
                ref={canvasParentRef}
                sx={{
                  maxWidth: "1024px",
                  margin: 0,
                  border: 1,
                  borderColor: "silver",
                  aspectRatio: 1,
                  boxShadow: ({boxShadows: {md}}) => md,
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: 0,
                }}
            >
              <MDBox
                  display="flex"
                  p={2}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    aspectRatio: 1
                  }}
                  {...getRootProps(
                      {
                        className: `dropzone ${referenceFile ? 'dropped' : ''}`
                      })}
              >
                <MDBox p={3} sx={{display: "flex", alignItems: "center"}}>
                  <MDBox mt={0.5} p={1}><Icon>mms</Icon></MDBox>
                  <MDTypography variant="button">Drag & drop a PNG or JPEG
                    image here, or click to select</MDTypography>
                </MDBox>
              </MDBox>
            </Card>
        )}
        <MDBox my={1} sx={{display: "flex", justifyContent: "left"}}>
          <MDButton
              disabled={
                  !referenceFile
                  || progressState.running
                  || referenceFileUploading
                  || referenceFileUploaded
                  || maskFileUploading
                  || maskFileUploaded
              }
              color="dark"
              onClick={handleUpload}
          >
            {(referenceFileUploading || maskFileUploading) && (
                <MDBox mr={1} className="small-spinner"></MDBox>)}
            {!referenceFileUploaded && !referenceFileUploading
                && !maskFileUploaded && !maskFileUploading
                && ("Remove marked area")}
            {(referenceFileUploading || maskFileUploading)
                && ("Uploading files...")}
            {referenceFileUploaded && maskFileUploaded && ("Files uploaded...")}
          </MDButton>
        </MDBox>
        {uploadErrorMessage && (<MDBox my={1}><MDTypography variant="button"
                                                            color="error">{uploadErrorMessage}</MDTypography></MDBox>)}
      </MDBox>
  );
}

export default ImageWithMaskUploader;
