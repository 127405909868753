import axios from "axios";

const workflowUrl = process.env.REACT_APP_API_URL + "/api/workflow"

class WorkflowClient {

  constructor(getAccessTokenSilently) {
    this.getAccessTokenSilently = getAccessTokenSilently
  }

  async requestGenerateImage(workflowType, variant, inputs) {
    const accessToken = await this.getAccessTokenSilently();
    const uuid = crypto.randomUUID();
    await axios.post(
        `${workflowUrl}/${workflowType}/variant/${variant}`,
        {
          id: uuid,
          properties: inputs
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
    );
    return uuid;
  }

  async requestActiveWorkflows(workflowType, variant, limit, before) {
    const accessToken = await this.getAccessTokenSilently();
    let url = `${workflowUrl}/${workflowType}/variant/${variant}`;

    const queryParams = {
      limit: limit,
      before: before
    };

    return axios.get(
        url,
        {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
    )
  }

  async uploadFile(file) {
    const accessToken = await this.getAccessTokenSilently();
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(
        process.env.REACT_APP_API_URL + '/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data' // Important for streaming uploads
          }
        });
  }

}

export default WorkflowClient;
