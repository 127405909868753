/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useRef, useState} from "react";

// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from "@mui/material/MenuItem";
import defaultValues from "./DefaultValues";
import presetValues from "./PresetValues";
import Card from "@mui/material/Card";
import PrettySlider from "../../../../mycomponents/PrettySlider";
import Switch from "@mui/material/Switch";

function HumanModelGeneratorInputs({
  requestHandler,
  progressState,
  definition
}) {
  const previousProgressState = useRef();

  useEffect(() => {
    if (previousProgressState.current && previousProgressState.current.running
        && !progressState.running && !progressState.error) {
      resetPrompts()
    }
    previousProgressState.current = progressState
  }, [progressState]);

  function resetPrompts() {
    // setCurrentInputs(defaultValues)
  }

  const [currentInputs, setCurrentInputs] = useState(defaultValues)

  async function handleSubmit(event) {
    event.preventDefault();
    requestHandler(definition.type, definition.variant, currentInputs)
  }

  function handleInput(propName) {
    const propertyName = propName
    return (event) =>
        setCurrentInputs((current) => ({
          ...current,
          [propertyName]: event.target.value
        }))
  }

  function handleBooleanInput(propName, enabledValue = "enable",
      disabledValue = "disable") {
    const propertyName = propName
    return (event) =>
        setCurrentInputs((current) => ({
          ...current,
          [propertyName]: event.target.checked ? enabledValue : disabledValue
        }))
  }

  function inputOptionSelection(propertyName, label, optionsSource) {
    return <MDBox mb={1}>
      <InputLabel sx={{p: 0.5}}>{label}</InputLabel>
      <Select fullWidth sx={{py: 1, mt: 1}} value={currentInputs[propertyName]}
              onChange={handleInput(propertyName)}>
        {optionsSource.map(option =>
            (<MenuItem key={option.value}
                       value={option.value}>{option.display}</MenuItem>))}
      </Select>
    </MDBox>;
  }

  function inputNumericRange(propertyName, label = null, step = 0.05,
      min = 0.0, max = 1.95, valueDisplay = "auto", sx = {}) {
    return <MDBox>
      {label && (<InputLabel sx={{p: 0.5}}>{label}</InputLabel>)}
      <PrettySlider
          value={currentInputs[propertyName]}
          min={min}
          max={max}
          step={step}  // Adjust step size for desired precision
          onChange={handleInput(propertyName)}
          valueLabelDisplay={valueDisplay}
          sx={sx}
      />
    </MDBox>;
  }

  function inputBooleanSelection(propertyName, label) {
    return <MDBox sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    }}>
      <InputLabel sx={{mt: 0.5}}>{label}</InputLabel>
      <Switch
          value={currentInputs[propertyName]}
          checked={currentInputs[propertyName] === "enable"}
          onChange={handleBooleanInput(propertyName)}
      />
    </MDBox>;
  }

  const inputsCardStyle = {py: 2, px: 4, height: "100%", minWidth: '250px'}
  const cardGridContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch"
  }

  return (
      <form onSubmit={handleSubmit}>
        <Grid container flex flexDirection="column">
          <Grid item>
            <Accordion defaultExpanded>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel2-content"
                  id="panel2-header"
              ><MDTypography color="dark" fontWeight="light" variant="h6">Shot
                type, Pose, Clothing, Make Up...</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={cardGridContainerStyle}>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("shot_type", "Shot Type",
                              presetValues.shot)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("model_pose",
                              "Model Pose", presetValues.model_pose)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("facial_expression",
                              "Expression", presetValues.face_expression)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("facial_expression_weight")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("clothes",
                              "Clothing", presetValues.clothes)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("makeup",
                              "Make Up", presetValues.makeup)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel3-content"
                  id="panel3-header"><MDTypography color="dark"
                                                   fontWeight="light"
                                                   variant="h6">Model
                Age, Gender, Ethnicity, Body Type, Face Shape,
                Beard...</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={cardGridContainerStyle}>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputNumericRange("age", "Age", 1, 18, 90, "on",
                              {mt: "45px"})}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("gender",
                              "Gender", presetValues.gender)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("androgynous", "Androgynous")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("nationality_1",
                              "Nationality One", presetValues.nationality)}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("nationality_2",
                              "Nationality Two", presetValues.nationality)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("nationality_mix",
                              "Nationality Mix", 0.05, 0.0, 1.0)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("body_type",
                              "Body Type", presetValues.body_type)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("body_type_weight")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("face_shape",
                              "Face Shape", presetValues.face_shape)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("face_shape_weight")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("facial_asymmetry",
                              "Facial Asymmetry")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("dimples", "Dimples")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("beard",
                              "Beard", presetValues.beard)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("body_type_weight")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel5-content"
                  id="panel5-header">
                <MDTypography color="dark" fontWeight="light" variant="h6">Hair,
                  Eyes, Lips...</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={cardGridContainerStyle}>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("hair_style",
                              "Hair Style", presetValues.hair_style)}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("hair_color",
                              "Hair Colour", presetValues.hair_color)}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("hair_length",
                              "Hair Length", presetValues.hair_length)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("disheveled", "Disheveled")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("eyes_color",
                              "Eyes Colour", presetValues.eyes_color)}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("eyes_shape",
                              "Eyes Shape", presetValues.eyes_shape)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputNumericRange("eyes_details", "Eyes Details")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("iris_details", "Iris Details")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("circular_iris", "Circular Iris")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("circular_pupil",
                              "Circular Pupil")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("lips_color",
                              "Lips Colour", presetValues.lips_color)}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("lips_shape",
                              "Lips Shape", presetValues.lips_shape)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel4-content"
                  id="panel4-header">
                <MDTypography color="dark" fontWeight="light" variant="h6">Wrinkles,
                  Freckles, Moles, Skin Features...</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={cardGridContainerStyle}>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputNumericRange("wrinkles", "Wrinkles")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("freckles", "Freckles")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("moles", "Moles")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputNumericRange("skin_acne", "Acne")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("skin_imperfections",
                              "Imperfections")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("natural_skin", "Natural Skin")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputNumericRange("skin_details", "Skin Details")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("skin_pores", "Pores")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("tanned_skin", "Tanned")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputNumericRange("bare_face", "Bare Face")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("washed_face", "Washed Face")}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("dried_face", "Dried Face")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1-content"
                  id="panel1-header">
                <MDTypography color="dark" fontWeight="light" variant="h6">Light,
                  Photography Style...</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={cardGridContainerStyle}>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("light_type", "Light Type",
                              presetValues.light_type)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("light_weight")}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("light_direction",
                              "Light Direction", presetValues.light_direction)}
                        </Grid>
                        <Grid item>
                          {inputBooleanSelection("photorealism_improvement",
                              "Photorealistic")}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={inputsCardStyle}>
                      <Grid container flex flexDirection="column">
                        <Grid item>
                          {inputOptionSelection("style_1", "Style One",
                              presetValues.style)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("style_1_weight", null, 0.05, 1.0)}
                        </Grid>
                        <Grid item>
                          {inputOptionSelection("style_2", "Style Two",
                              presetValues.style)}
                        </Grid>
                        <Grid item>
                          {inputNumericRange("style_2_weight", null, 0.05, 1.0)}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox sx={{mt: 2}}>
                  <MDButton
                      disabled={progressState.running}
                      color="dark"
                      type="submit">Generate</MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography variant="button" fontWeight="light"
                                color={progressState.error ? "error" : "text"}>
                    {progressState.message}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
  );
}

export default HumanModelGeneratorInputs;
