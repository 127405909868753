import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";

function SaveImageButton({filename, imageUrl, sx}) {

  const handleSaveClick = () => {
    axios.get(imageUrl, {
      maxRedirects: 1,
      responseType: 'blob',
      mode: 'no-cors'
    }).then((response) => {
          const link = document.createElement('a');
          link.download = filename;
          link.href = URL.createObjectURL(response.data);
          link.click();
          URL.revokeObjectURL(link.href);
        }
    );
  };

  return (
      <IconButton aria-label="save"
                  aria-label="zoom"
                  onClick={handleSaveClick}
                  sx={sx}>
        <DownloadIcon/>
      </IconButton>
  );
}

export default SaveImageButton
