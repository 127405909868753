/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/dashboard/components/Header";

// Data
// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import humanModel1 from "assets/images/human_model_1.jpg";
import imageStyling1 from "assets/images/image_style_1.jpg"
import DefaultGeneratorCard
  from "../../examples/Cards/GeneratorTypeCards/DefaultGeneratorCard";
import {useAuth0} from "@auth0/auth0-react";
import MDButton from "../../components/MDButton";
import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import ReactGA from "react-ga4";

function Dashboard() {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently
  } = useAuth0();

  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    if (!isAuthenticated) {
      setPermissions([]);
      return
    }
    const fetchToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const decodedToken = jwtDecode(accessToken);
      setPermissions(decodedToken.permissions);
      ReactGA.set({ userId: decodedToken.custom_email_claim });
    }
    fetchToken()
  }, [isAuthenticated, getAccessTokenSilently])

  let contents;
  if (isLoading) {
    contents = (
        <>
          <MDBox pt={2} pb={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium">
              Loading. Please wait...
            </MDTypography>
          </MDBox>
        </>
    )
  } else if (isAuthenticated) {
    if (permissions.includes("generate:text2image") || permissions.includes("generate:human_model") || permissions.includes("generate:styling")) {
      contents = (
          <>
            <MDBox pt={2} px={2} lineHeight={1.25}>
              <MDTypography variant="h6" fontWeight="medium">
                Generators
              </MDTypography>
              <MDBox mb={1}>
                <MDTypography variant="button" color="text">
                  Select type of generator
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={6} direction="row" justifyContent="flex-start" alignItems="stretch">
                { permissions.includes("generate:text2image") && (
                    <>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultGeneratorCard
                      image={homeDecor1}
                      label="Fast Text To Image"
                      title="Fast Generator"
                      description="Provide text description of desired output to generate images fast"
                      action={{
                        type: "internal",
                        route: "/text-to-image/fast",
                        color: "info",
                        label: "Open generator",
                      }}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultGeneratorCard
                      image={homeDecor3}
                      label="Quality Text To Image"
                      title="Quality Generator"
                      description="Provide text description of desired and undesired output to generate batch of images of higher quality at expense of time"
                      action={{
                        type: "internal",
                        route: "/text-to-image/quality",
                        color: "info",
                        label: "Open generator",
                      }}
                  />
                </Grid>
                    </>) }
                { permissions.includes("generate:human_model") && (
                    <>
                      <Grid item xs={12} md={6} xl={3}>
                        <DefaultGeneratorCard
                            image={humanModel1}
                            label="Studio Photoshoot"
                            title="Photoshoot Generator"
                            description="Craft the perfect fashion images without a physical studio. Select your ideal model, pose and more for custom photoshoots at your fingertips."
                            action={{
                              type: "internal",
                              route: "/model-photoshoot",
                              color: "info",
                              label: "Open generator",
                            }}
                        />
                      </Grid>
                    </>) }
                { permissions.includes("generate:styling") && (
                    <>
                      <Grid item xs={12} md={6} xl={3}>
                        <DefaultGeneratorCard
                            image={imageStyling1}
                            label="Apply Style To Image"
                            title="Style Like Another Image"
                            description="This AI generator allows you to blend the artistic qualities of one image into another. It can transform your photos into impressionist paintings, apply a classic style to a modern scene, or create entirely new visual combinations."
                            action={{
                              type: "internal",
                              route: "/styling/like-image",
                              color: "info",
                              label: "Open generator",
                            }}
                        />
                      </Grid>
                    </>) }
              </Grid>
            </MDBox>
          </>
      )
    } else {
      contents = (
          <MDBox p={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium">
              You do not have permissions to use the application yet
            </MDTypography>
          </MDBox>
      )
    }
  } else {
    contents = (
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Sign in to use the application
          </MDTypography>
          <MDBox my={1}>
            <MDButton variant="gradient" color="success"
                      onClick={() => loginWithRedirect()}>
              Sign In
            </MDButton>
          </MDBox>
        </MDBox>
    )
  }

  return (
      <DashboardLayout>
        <DashboardNavbar/>
        <MDBox mb={2}/>
        <Header>
          {contents}
        </Header>
        <Footer/>
      </DashboardLayout>
  );
}

export default Dashboard;
