/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 This file is used for controlling the global states of the components,
 you can customize the states for the different components here.
 */

import {createContext, useContext, useMemo, useReducer} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

function extractParamsFromState(updatedState) {
  const paramsToPersist = {
    miniSidenav: updatedState.miniSidenav,
    transparentSidenav: updatedState.transparentSidenav,
    whiteSidenav: updatedState.whiteSidenav,
    sidenavColor: updatedState.sidenavColor,
    fixedNavbar: updatedState.fixedNavbar,
    direction: updatedState.direction,
    layout: updatedState.layout,
    darkMode: updatedState.darkMode,
  }
  return paramsToPersist;
}

// Material Dashboard 2 React reducer
function reducer(state, action) {
  const currentParams = JSON.stringify(extractParamsFromState(state));
  let updatedState = state;
  switch (action.type) {
    case "MINI_SIDENAV": {
      updatedState = {...state, miniSidenav: action.value};
      break;
    }
    case "TRANSPARENT_SIDENAV": {
      updatedState = {...state, transparentSidenav: action.value};
      break;
    }
    case "WHITE_SIDENAV": {
      updatedState = {...state, whiteSidenav: action.value};
      break;
    }
    case "SIDENAV_COLOR": {
      updatedState = {...state, sidenavColor: action.value};
      break;
    }
    case "TRANSPARENT_NAVBAR": {
      updatedState = {...state, transparentNavbar: action.value};
      break;
    }
    case "FIXED_NAVBAR": {
      updatedState = {...state, fixedNavbar: action.value};
      break;
    }
    case "OPEN_CONFIGURATOR": {
      updatedState = {...state, openConfigurator: action.value};
      break;
    }
    case "DIRECTION": {
      updatedState = {...state, direction: action.value};
      break;
    }
    case "LAYOUT": {
      updatedState = {...state, layout: action.value};
      break;
    }
    case "DARKMODE": {
      updatedState = {...state, darkMode: action.value};
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  if (updatedState !== state) {
    const paramsToPersist = JSON.stringify(
        extractParamsFromState(updatedState));
    if (currentParams !== paramsToPersist) {
      localStorage.setItem("eikona-display-preferences", paramsToPersist)
    }
  }
  return updatedState;
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({children}) {

  let initialStateProvider = (initialArg) => {
    let defaultInitialState = {
      miniSidenav: false,
      transparentSidenav: true,
      whiteSidenav: false,
      sidenavColor: "info",
      transparentNavbar: true,
      fixedNavbar: true,
      openConfigurator: false,
      direction: "ltr",
      layout: "dashboard",
      darkMode: false,
    }
    const persistedState = JSON.parse(
        localStorage.getItem("eikona-display-preferences")) || {};
    const initialState = {...defaultInitialState, ...persistedState}
    return initialState
  }

  const [controller, dispatch] = useReducer(reducer, {}, initialStateProvider);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
        "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch(
    {type: "MINI_SIDENAV", value});
const setTransparentSidenav = (dispatch, value) => dispatch(
    {type: "TRANSPARENT_SIDENAV", value});
const setWhiteSidenav = (dispatch, value) => dispatch(
    {type: "WHITE_SIDENAV", value});
const setSidenavColor = (dispatch, value) => dispatch(
    {type: "SIDENAV_COLOR", value});
const setTransparentNavbar = (dispatch, value) => dispatch(
    {type: "TRANSPARENT_NAVBAR", value});
const setFixedNavbar = (dispatch, value) => dispatch(
    {type: "FIXED_NAVBAR", value});
const setOpenConfigurator = (dispatch, value) => dispatch(
    {type: "OPEN_CONFIGURATOR", value});
const setDirection = (dispatch, value) => dispatch({type: "DIRECTION", value});
const setLayout = (dispatch, value) => dispatch({type: "LAYOUT", value});
const setDarkMode = (dispatch, value) => dispatch({type: "DARKMODE", value});

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
