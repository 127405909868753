import axios from "axios";

const workflowUrl = process.env.REACT_APP_API_URL + "/api/token"

class TokenBalanceClient {
  constructor(getAccessTokenSilently) {
    this.getAccessTokenSilently = getAccessTokenSilently
  }

  async requestCurrentBalance() {
    const accessToken = await this.getAccessTokenSilently();
    return axios.get(
        `${workflowUrl}/balance`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
    );
  }

  async costForGenerateImageVariant(type, variant) {
    const accessToken = await this.getAccessTokenSilently();
    return axios.get(
        `${workflowUrl}/cost`,
        {
          params: {
            type: type,
            variant: variant
          },
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
    );
  }
}

export default TokenBalanceClient;
