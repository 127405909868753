import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

const websocketUrl = process.env.REACT_APP_API_URL + "/websocket"

class NotificationsClient {
  constructor(onNotification, getAccessTokenSilently) {
    this.stompClient = null;
    this.subscriptions = new Set();
    this.onNotification = onNotification;
    this.getAccessTokenSilently = getAccessTokenSilently
  }

  ensureConnected() {
    if (this.stompClient == null) {
      this.stompClient = Stomp.over(new SockJS(websocketUrl));
      this.getAccessTokenSilently().then(accessToken => {
            let headers = {
              Authorization: `Bearer ${accessToken}`
            };
            this.stompClient.connect(headers, this.onConnected, this.onError);
          }
      );
    }
  }

  onConnected = () => {
    try {
      if (this.stompClient.connected) {
        this.subscriptions.forEach(topic => {
              this.stompClient.subscribe(topic, this.onNotification);
            }
        );
      }
    } catch (e) {
      console.error("Re-subscribe failed. Will retry later...", e)
    }
  }

  subscribe(topic) {
    this.subscriptions.add(topic);
    this.ensureConnected();
    try {
      if (this.stompClient.connected) {
        this.stompClient.subscribe(topic, this.onNotification);
      }
    } catch (e) {
      console.error("Subscribe failed. Will retry later...", e)
    }
  }

  unsubscribe(topic) {
    this.subscriptions.delete(topic);
    this.stompClient.unsubscribe(topic);
  }

  onError = (error) => {
    console.warn("WS Error:", error);
    setTimeout(this.reconnect, 5000);
  }

  reconnect = () => {
    if (this.stompClient !== null) {
      try {
        this.stompClient.disconnect();
      } catch (e) {
      }
    }
    this.stompClient = null;
    this.ensureConnected();
  }
}

export default NotificationsClient
