/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import backgroundImage from "assets/images/bg.jpg";

function Header({
  title,
  description,
  currentBalance,
  currentVariantCost,
  children = ""
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
          ? setTabsOrientation("vertical")
          : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
      <MDBox position="relative" mb={5}>
        <MDBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="18.75rem"
            borderRadius="xl"
            sx={{
              backgroundImage: ({
                functions: {rgba, linearGradient},
                palette: {gradients}
              }) =>
                  `${linearGradient(
                      rgba(gradients.info.main, 0.6),
                      rgba(gradients.info.state, 0.6)
                  )}, url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
        />
        <Card
            sx={{
              position: "relative",
              mt: -8,
              mx: {xs: 1, md: 3},
              py: 2,
              px: 2,
            }}
        >

          <MDBox height="100%" p={2} lineHeight={1}>
            <MDBox>
              <MDTypography textTransform="capitalize" variant="h5"
                            fontWeight="medium"
                            sx={{display: "flex", alignItems: "center"}}>
                {title}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {description}
              </MDTypography>
            </MDBox>
            {currentBalance && currentVariantCost && <MDBox sx={{mt: 1}}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                Available Balance: {currentBalance}<br/>
                Generation Cost: {currentVariantCost}
              </MDTypography>
            </MDBox>
            }
          </MDBox>

          {children}
        </Card>
      </MDBox>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
