/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useRef, useState} from "react";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

function TextPromptGeneratorInputs({
  requestHandler,
  progressState,
  definition
}) {
  const [positivePrompt, setPositivePrompt] = useState("");
  const [negativePrompt, setNegativePrompt] = useState("text, watermark");
  const [imageResolution, setImageResolution] = useState("1024 x 1024")

  const showNegativeInput = definition.variant === "quality"

  const previousProgressState = useRef();

  useEffect(() => {
    if (previousProgressState.current && previousProgressState.current.running
        && !progressState.running && !progressState.error) {
      setPositivePrompt("")
    }
    previousProgressState.current = progressState
  }, [progressState]);

  async function handleSubmit(event) {
    event.preventDefault();
    const positivePromptParam = positivePrompt
    const negativePromptParam = negativePrompt
    requestHandler(definition.type, definition.variant, {
      prompt: positivePromptParam,
      negativePrompt: negativePromptParam,
      resolution: imageResolution,
    })
  }

  return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={showNegativeInput ? 6 : 12}>
            <MDBox>
              <MDInput
                  disabled={progressState.running}
                  label="Describe what you want to generate..."
                  fullWidth
                  multiline
                  rows={3}
                  value={positivePrompt}
                  onChange={(event) => setPositivePrompt(
                      event.target.value)}
              />
            </MDBox>
          </Grid>
          {showNegativeInput &&
              <Grid item xs={12} md={6}>
                <MDBox>
                  <MDInput
                      disabled={progressState.running}
                      label="Describe what you do not want to be included..."
                      fullWidth
                      multiline
                      rows={3}
                      value={negativePrompt}
                      onChange={(event) => setNegativePrompt(
                          event.target.value)}
                  />
                </MDBox>
              </Grid>
          }
          <Grid item xs={12}>
            <MDBox sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              mt: 2
            }}>
              {definition.variant === "quality" && (
                  <MDBox>
                    <FormControl>
                      <InputLabel id="resolution-label">Resolution</InputLabel>
                      <Select value={imageResolution} sx={{py: 1.5, px: 1}}
                              label="Resolution" labelId="resolution-label"
                              id="resolution"
                              onChange={(event) => setImageResolution(
                                  event.target.value)}>
                        <MenuItem value="1024 x 1024">1024 x 1024</MenuItem>
                        <MenuItem value="1152 x 896">1152 x 896</MenuItem>
                        <MenuItem value="896 x 1152">896 x 1152</MenuItem>
                        <MenuItem value="1216 x 832">1216 x 832</MenuItem>
                        <MenuItem value="832 x 1216">832 x 1216</MenuItem>
                        <MenuItem value="1344 x 768">1344 x 768</MenuItem>
                        <MenuItem value="768 x 1344">768 x 1344</MenuItem>
                        <MenuItem value="1536 x 640">1536 x 640</MenuItem>
                        <MenuItem value="640 x 1536">640 x 1536</MenuItem>
                      </Select>
                    </FormControl>
                  </MDBox>
              )
              }
              <MDBox>
                <MDButton
                    disabled={!positivePrompt || progressState.running}
                    color="dark"
                    type="submit">Generate</MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox>
              <MDTypography variant="button" fontWeight="light"
                            color={progressState.error ? "error" : "text"}>
                {progressState.message}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </form>
  );
}

export default TextPromptGeneratorInputs;
