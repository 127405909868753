import pxToRem from "../../functions/pxToRem";

const accordionDetails = {
  styleOverrides: {
    root: {
      backgroundColor: "#232b43",
      padding: pxToRem(20),
    },
  },
};

export default accordionDetails;
